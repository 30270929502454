import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Column,
  Divider,
  Icon,
  Link,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { eventClick } from '@smu-chile/pkg-unimarc-hooks'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'

export interface SubcategoriesProps {
  data: {
    label: string;
    url: string;
  }[]
}

export const Subcategories = ({ data }: SubcategoriesProps) => {
  const containerSubCategoriesRef = useRef(null)
  const [haveScroll, setHaveScroll] = useState(false)
  const [isPrev, setIsPrev] = useState(false)
  const [isNext, setIsNext] = useState(true)

  const handleChangeSubcategories = (type: string) => {
    let left = 0

    const frame = () => {
      left += 55
      const valueScrollLeft = containerSubCategoriesRef['current']['scrollLeft']
      const newValue = type == 'next' ? valueScrollLeft + left : valueScrollLeft - left
      containerSubCategoriesRef['current']['scrollLeft'] = newValue

      if (left >= 500) {
        clearInterval(id)
        handleValidateShows()
      }
    }

    const id = setInterval(frame, 10)
  }

  const handleValidateShows = () => {
    const havePrev = containerSubCategoriesRef.current?.scrollLeft != 0
    setIsPrev(havePrev)

    const haveNext = Math.floor(
      containerSubCategoriesRef.current?.scrollWidth - containerSubCategoriesRef.current?.scrollLeft
    ) > containerSubCategoriesRef.current?.offsetWidth + 35
    setIsNext(haveNext)
  }

  const handleClickPill = ({label}) => {
    const pageType = window.location.pathname.includes('search') ? 'SRP' : 'PLP'
    eventClick({eventCategory: `Interacciones en ${pageType}`, eventAction: 'Clic pildora', 'evento-interactivo-etiqueta': label})
  }

  useEffect(() => {
    const allowScroll = containerSubCategoriesRef.current?.scrollWidth > containerSubCategoriesRef.current?.clientWidth
    setHaveScroll(allowScroll)
    handleValidateShows()
  }, [data])

  return (
    <>
      <BigScreen>
        <>
          <Divider verticalMargin={16} />
          <Row alignItems='center' position='relative'>
            {haveScroll && isPrev &&
              <Column
                margin='0 0 8px'
                maxWidth='32px'
                onClick={() => {
                  return handleChangeSubcategories('before')
                }}
              >
                <Icon
                  clickable='pointer'
                  color='#666666'
                  customSize={20}
                  name='ArrowBackNavigate'
                />
              </Column>
            }
            <Row
              overflow='hidden'
              padding='0 35px 0 0 '
              ref={containerSubCategoriesRef}
            >
              {data?.map(item => {
                return (
                  <Row
                    alignItems='center'
                    key={item.label}
                    margin='0 0 8px'
                    maxWidth='max-content'
                  >
                    <Link
                      href={`/category${item.url}`}
                      onClick={() => {handleClickPill({label: item.label})}}
                      preventDefault={false}
                    >
                      <Button
                        label={item.label}
                        minWidth='auto'
                        type='sub-category'
                      />
                    </Link>
                    <Spacer.Vertical size={8} />
                  </Row>
                )
              })}
            </Row>
            {haveScroll && isNext &&
              <Column
                absoluteDefault='topRight'
                alignItems='end'
                background='linear-gradient(
                90deg, rgba(255, 255, 255, 0)
                -66.67%, rgba(255, 255, 255, 0.17)
                -9.27%, rgb(249, 249, 249) 46.81%)'
                justifyContent='center'
                maxWidth='60px'
                minHeight='48px'
                onClick={() => {
                  return handleChangeSubcategories('next')
                }}
                padding='0 0 8px'
                position='absolute'
              >
                <Icon
                  clickable='pointer'
                  color='#666666'
                  customSize={20}
                  name='ArrowRightNavigate'
                />
              </Column>
            }
          </Row>
          <Divider verticalMargin={8} />
        </>
      </BigScreen >

      <SmallScreen>
        <>
          <Row
            overflow='auto'
            padding='16px 12px'
          >
            {data?.map(item => {
              return (
                <Row
                  key={item.label}
                  maxWidth='max-content'
                >
                  <Link
                    href={`/category${item.url}`}
                    onClick={() => {handleClickPill({label: item.label})}}
                    preventDefault={false}
                  >
                    <Button
                      label={item.label}
                      minWidth='auto'
                      type='sub-category'
                    />
                  </Link>
                  <Spacer.Vertical size={4} />
                </Row>
              )
            })}
          </Row>
        </>
      </SmallScreen>
    </>
  )
}