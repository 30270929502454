import React, { useEffect } from 'react'
import {
  generateBreadcrumbJoin,
  useCart,
  useUserAlvi,
  visiblePromosScroll
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Container,
  Column,
  Spacer,
  RenderStringHtml
} from '@smu-chile/pkg-unimarc-components'
import { BackboneHome } from './Backbone'
import { BannersWeb } from './BannersWeb'
import { PropsHome } from 'shared/interfaces/Home'
import { ShowCaseWeb } from './ShowCaseWeb'
import { useRouter } from 'next/router'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { RenderProductsCarousel } from 'components/ProductsCarousel/RenderProductsCarousel'

export const Home = ({ data, isMobile }: PropsHome) => {
  const router = useRouter()
  const orderForm = useCart()
  const { data: user } = useUserAlvi() || {}
  const userId = user?.userId
  const userLevel = user?.level
  const isFallback = router.isFallback
  const site = 'Alvi'
  const spacerSize = isMobile ? 4 : 16
  let undefinedContent = 0
  const customTitles = {
    'h2': {
      customFontSize: isMobile ?
        getGlobalStyle('--font-size-titles-xs') :
        getGlobalStyle('--font-size-titles-lg')
    },
    'h3': {
      customFontSize: isMobile ?
        getGlobalStyle('--font-size-titles-2xs') :
        getGlobalStyle('--font-size-titles-md')
    }
  }

  useEffect(() => {
    if(user && orderForm.data){
      generateBreadcrumbJoin([])
      visiblePromosScroll({site, userId, orderForm: orderForm?.data})
    }
  }, [user, orderForm])

  return (
    <>
      {isFallback ?
        <BackboneHome />
        :
        <Container background={getGlobalStyle('--color-alvi-neutral-gray-white')} tagName='main'>
          <Column alignItems='center'>
            <Spacer.Horizontal size={spacerSize} />

            {Object?.entries(data)?.map((content, index) => {

              // carousel showcaseWeb
              if (/showcaseWeb/i.test(content[0])) {
                return (
                  <ShowCaseWeb
                    cfIndex={index + 1 - undefinedContent}
                    data={content[1]['slider']}
                    key={index}
                    orderForm={orderForm}
                    site={site}
                    userId={userId}
                  />
                )
              }

              // banners
              if (/featureContentBannersWeb/i.test(content[0])) {
                return (
                  <React.Fragment key={index}>
                    <BannersWeb
                      cfIndex={index + 1 - undefinedContent}
                      data={content[1]}
                      isMobile={isMobile}
                      key={index}
                      orderForm={orderForm}
                      site={site}
                      titleSizes={customTitles}
                      userId={userId}
                      userLevel={userLevel}
                    />
                    {index != 0 && <Spacer.Horizontal size={isMobile ? 16 : 32} />}
                  </React.Fragment>
                )
              }

              if (/productsCarouselWeb/i.test(content[0])) {
                return (
                  <RenderProductsCarousel
                    content={content[1]}
                    customTitles={customTitles}
                    index={index}
                    key={index}
                  />
                )
              }

              if (/term/i.test(content[0])) {
                return (
                  <RenderStringHtml
                    htmlString={content[1]?.html}
                    key={index}
                  />
                )
              }

              undefinedContent += 1
              return null
            })}
          </Column>
        </Container>
      }
    </>
  )
}