import { useEffect } from 'react'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  Container,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  generateBreadcrumbJoin,
  usePagination
} from '@smu-chile/pkg-unimarc-hooks'
import { contentBannerLegal, promotionsOfShelves } from '../../shared/helpers'
import { BodyPageProducts } from 'components/BodyPageProducts'
import { ErrorPageSearch } from 'components/ProductSearchPage/ErrorPageSearch'
import { OffersProps } from 'pages/ofertas/[...offers]'
import { MonetiseBanner, LegalTerms } from 'components'

export const ProductOfferPage = ({
  data,
  page,
  promotions,
  title
}: OffersProps) => {
  const dataBreadCrumb = [
    { label: 'Inicio', url: '/', oneClick: () => { return null } },
    { label: `“${title}”`, url: '#' }
  ]
  generateBreadcrumbJoin(dataBreadCrumb)
  // Get all promotions available in contenful

  const {
    bannerImages,
    contentLegalParagraph,
    contentLegalTitle
  } = contentBannerLegal(data?.bannerLegal)
  const urlLinkOffer = data?.bannerLegal?.items[0]?.fields?.urlRedirect

  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: promotions })
  const products = data?.products
  const totalQuantity = parseInt(data?.resources) || 0

  const setProductsRange = (next: number) => {
    return next
  }
  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: setProductsRange
  })

  const dataOfBodyPageProducts = {
    breadcrumbData: dataBreadCrumb,
    title,
    totalQuantity,
    propsNavigation,
    isLoadingPage: false,
    products,
    dataPromotions
  }

  useEffect(() => {
    setProductsRange(page)
  }, [])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  const Main = ({ children }: { children: React.ReactNode }) => {
    return (
      <Container
        alignItems='start'
        backgroundColor={getGlobalStyle('--color-background-white')}
        justifyContent='center'
        tagName='main'
      >
        {children}
      </Container>
    )
  }

  if (totalQuantity == 0) {
    return (
      <Main>
        <ErrorPageSearch message='resultados para' search={title} />
      </Main>
    )
  }

  return (
    <Main>
      <BigScreen>
        <Column alignItems='center'>
          {bannerImages &&
            <MonetiseBanner
              images={bannerImages}
              padding='32px 0 0 0'
              urlLink={urlLinkOffer}
            />
          }
          {!bannerImages && <Spacer.Horizontal size={24} />}
          <Container
            backgroundColor={getGlobalStyle('--color-background-white')}
            customWidth='90'
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='12px 36px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
            <LegalTerms
              paragraph={contentLegalParagraph}
              title={contentLegalTitle}
            />
          </Container>
        </Column >
      </BigScreen >
      <SmallScreen>
        <Column backgroundColor={getGlobalStyle('--color-neutral-gray-light')}>
          {!bannerImages
            ? <Spacer.Horizontal size={32} />
            : <Spacer.Horizontal size={48} />
          }
          {bannerImages &&
            <MonetiseBanner
              images={bannerImages}
              padding='8px 12px'
              urlLink={urlLinkOffer}
            />
          }
          <BodyPageProducts {...dataOfBodyPageProducts} />
          <LegalTerms
            paragraph={contentLegalParagraph}
            title={contentLegalTitle}
          />
        </Column >
      </SmallScreen >
    </Main>
  )
}