/**
 * Return sanitize rut, without `.` and `-`, and only one `k ro K`
 * @param {string} rut - dirty rut value
 * @returns {string} sanitize rut
 */
export function cleanRut(rut: string): string {
  let sanitize = !rut ? '' : rut.trim()

  sanitize = sanitize.replace(/(^(0+|[^0-9kK]+))|\.|-/g, '')

  const [result] = sanitize.match(/(\d{0,8}(\d|k|K)?)/) ?? []

  return result ?? ''
}
