import {
  useEffect,
  useState
} from 'react'
import {
  Button,
  Column,
  Container,
  Modal,
  Notification,
  Picture,
  Row,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useUpdateDocumentAlvi } from '@smu-chile/pkg-unimarc-hooks'
import { UpdateDocumentInput } from '../UpdateDocumentInput'

export interface UpdateDocumentModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onFailed?: () => void;
  onSuccess?: () => void;
}

export const UpdateDocumentModal = ({
  isOpen,
  onClose,
  onFailed,
  onSuccess
}: UpdateDocumentModalProps) => {
  const [file, setFile] = useState<File>(null)
  const [notificationShow, setNotificationShow] = useState<boolean>(false)

  const {
    data: response,
    isFetching,
    refetch: updateDocumentFetch
  } = useUpdateDocumentAlvi({
    contentType: file?.type,
    file,
    reactQuery: {
      cacheTime: 0,
      enabled: false
    }
  })

  const buttonStatusDefault = isFetching ? 'loading' : 'initial'
  const buttonStatus = file ? buttonStatusDefault : 'disabled'

  const handleUpdateDocumentDataChange = () => {
    switch (response?.status) {
      case 200:
        if (onSuccess) onSuccess()
        break
      case 403:
        setNotificationShow(true)
        if (onFailed) onFailed()
        break
    }
  }

  useEffect(handleUpdateDocumentDataChange, [response])

  const handleButtonContinueClick = async () => {
    await updateDocumentFetch()
  }

  const handleNotificationClose = () => {
    setNotificationShow(false)
  }

  const handleUpdateDocumentInputChange = (file?: File) => {
    setFile(file)
  }

  return (
    <>
      <BigScreen>
        <Modal
          iconPadding='1.5em'
          isOpen={isOpen}
          maxWidth='820px'
          padding='0'
          toggle={onClose}
        >
          <Row>
            <Picture
              height='500px'
              lineHeight={0}
              objectFit='fill'
              src='https://images.ctfassets.net/un6yvtd6uq5z/6kPuN2QdLOVRYGYZN4dINL/0e3fb0948cdaab67608f8b07e404d097/Frame_7934.png'
              width='400px'
            />

            <Column justifyContent='between' minHeight='500px'>
              <Column gap={16} padding='2.5em'>
                <Container position='relative'>
                  <Notification
                    align='top-center'
                    message='Error al subir documento, vuelva a intentarlo nuevamente en unos minutos.'
                    onClose={handleNotificationClose}
                    show={notificationShow}
                    showCloseIcon
                    site='alvi'
                  />
                </Container>

                <Column gap={8}>
                  <Title fontWeight='semibold'>
                    Actualizar documento
                  </Title>

                  <Text fontSize='md'>
                    Debes subir un documento comercial o foto que acredita que eres un socio comerciante.
                  </Text>
                </Column>

                <UpdateDocumentInput onChange={handleUpdateDocumentInputChange} />
              </Column>

              <Container justifyContent='center' padding='1.5em'>
                <Button
                  borderRadius='24px'
                  fontSize='14px'
                  fontWeight='500'
                  fullWidth
                  label='Continuar'
                  maxWidth='300px'
                  onClick={handleButtonContinueClick}
                  site='alvi'
                  size='large'
                  status={buttonStatus}
                />
              </Container>
            </Column>
          </Row>
        </Modal>
      </BigScreen>

      <SmallScreen>
        <Modal
          fullScreen
          iconPadding='1.5em'
          isOpen={isOpen}
          padding='0'
          toggle={onClose}
        >
          <Column customHeight='calc(100vh - 4rem)' justifyContent='between'>
            <Column gap={16} padding='2rem'>
              <Container position='relative'>
                <Notification
                  align='top-center'
                  message='Error al subir documento, vuelva a intentarlo nuevamente en unos minutos.'
                  onClose={handleNotificationClose}
                  show={notificationShow}
                  showCloseIcon
                  site='alvi'
                />
              </Container>

              <Column gap={8}>
                <Text fontSize='xl' fontWeight='semibold'>
                  Actualizar documento
                </Text>

                <Text fontSize='md'>
                  Debes subir un documento comercial o foto que acredite que eres un socio comerciante.
                </Text>
              </Column>

              <UpdateDocumentInput onChange={handleUpdateDocumentInputChange} />
            </Column>

            <Container
              boxShadow={getGlobalStyle('--box-shadow-xs')}
              justifyContent='center'
              padding='.75rem 2.5rem 2.5rem'
            >
              <Button
                borderRadius='24px'
                fontSize='14px'
                fontWeight='500'
                fullWidth
                label='Continuar'
                maxWidth='300px'
                onClick={handleButtonContinueClick}
                site='alvi'
                size='large'
                status={buttonStatus}
              />
            </Container>
          </Column>
        </Modal>
      </SmallScreen>
    </>
  )
}
