import { useRouter } from 'next/router'
import {
  Backbone,
  Pagination,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { clickPlp } from 'shared/utils/googleAnalytics'

export interface CustomPaginationProps {
  currentPage: number;
  isLoadingPage: boolean;
  isVisableButtonLeft: boolean;
  isVisableButtonRight: boolean;
  isVisableButtons: boolean;
  items: (string | number)[];
  pageQuantity: number;
  urlParams?: string;
  backgroundCurrent?: string;
}

export const CustomPagination = ({
  isLoadingPage,
  backgroundCurrent = getGlobalStyle('--color-alvi-primary-blue-gradient'),
  ...propsNavigation
}: CustomPaginationProps) => {
  const router = useRouter()
  const hasFilterQuery = Object.keys(router?.query || {}).filter((key) => {
    return key === 'orderBy' || key === 'q'
  })
  const queries = hasFilterQuery.map((queryName) => {
    return `${queryName}=${router?.query?.[queryName]}`
  })
  if (isLoadingPage) {
    return (
      <Row customWidth='25'>
        <Backbone borderRadius='20px' height={30} />
      </Row>
    )
  }

  return (
    <Pagination
      {...
        { ...propsNavigation,
          isLoadingPage,
          path: router?.asPath?.replace(/\?.*/, ''),
          urlParams: `${queries.join('&')}`
        }}
      backgroundCurrent={backgroundCurrent}
      handleNextPage={() => {
        clickPlp('Siguiente', 'paginacion')
      }}
      handleOnClick={number => {
        clickPlp(number, 'paginacion')
      }}
      handlePrevPage={() => {
        clickPlp('Anterior', 'paginacion')
      }}
    />
  )
}