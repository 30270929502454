import {
  CSSProperties,
  useEffect,
  useRef,
  useState
} from 'react'
import { useRouter } from 'next/router'
import { Container, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  cleanUrlParamsLogin,
  useEvents,
  Cookies,
  useQueryClient,
  sleep,
  useAlviSession,
  useUpdateQuantityQuicklyAlvi,
  useCart,
  updateTagOrderForm
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Footer,
  HeaderBrowse,
  StoreLocatorComponent,
  NoStockModal
} from '..'
import { DataResponseCart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICart'
import { ModalMembershipTypeWrapper } from 'components/ModalMembershipTypeWrapper'
import { ModalMembershipLevelQueryWrapper } from 'components/ModalMembershipLevelQueryWrapper'
import { useQueryMembershipLevel } from './helpers'
import { ModalRegisterClubWrapper } from 'components/ModalRegisterClubWrapper'

export interface LayoutProps {
  children: React.ReactNode;
  backgroundColor?: CSSProperties['backgroundColor'];
}

export const Layout = ({ backgroundColor, children }: LayoutProps): React.ReactElement => {
  const router = useRouter()
  const { isLoggedIn } = useAlviSession()
  const { handleMutate } = useUpdateQuantityQuicklyAlvi('cart')
  const queryClient = useQueryClient()
  const [isShowStockModal, setIsShowNoStockModal] = useState(false)
  const messageNoStockModal = useRef([])
  const background = backgroundColor ?? getGlobalStyle('--color-alvi-neutral-gray-white')
  const {
    data,
    isLoading
  } = useCart({
    reactQuery: {
      enabled: isLoggedIn
    }
  })
  // custom hooks to handle membership level query
  const {
    showModalMembershipLevelQuery,
    blockedBySecurity,
    showModalMembershipType,
    membershipType,
    showModalRegisterClub,
    isLoading: isLoadingMembershipQueryLevel,
    setShowModalMembershipLevelQuery,
    handleMembershipLevelQueryContinue,
    handleToggleMembershipLevelQuery,
    handleToggleMembershipType,
    handleToggleRegisterClub
  } = useQueryMembershipLevel()

  useEvents({
    eventType: 'addToCart', callBack: ({ detail: { items } }) => {
      handleMutate(items)
    }
  })

  // Event to validate if the orderform generate error with some item
  // and show the noStockModal
  useEvents({
    eventType: 'loadingOrderForm', callBack: async ({ detail: { loading } }) => {
      if (loading) return
      sleep(1000)
      const getMutateData = queryClient.getMutationCache()
        .findAll({ mutationKey: 'mutateOrderForm' })
        .slice(-1)[0]
      const orderFormMutate = getMutateData?.state?.data as DataResponseCart
      if (orderFormMutate?.failed?.length > 0) {
        ['failed'].forEach(typeError => {
          orderFormMutate?.[typeError]?.forEach(({ message }) => {
            messageNoStockModal.current.push(message)
          })
        })
        setIsShowNoStockModal(false)
      }
    }
  })

  const handleCloseNoStockModal = () => {
    setIsShowNoStockModal(false)
    messageNoStockModal.current = []
  }

  useEffect(() => {
    cleanUrlParamsLogin({
      router,
      ignoreItems: ['q', 'page', 'source', 'GoSSO', 'GotoMembership']
    })
    const showModalMembershipLevelQueryByTime = async () => {
      await sleep(1500)
      // show modal balance inquiry membership
      // to the client consultation flow
      if (router?.query?.membresia) {
        setShowModalMembershipLevelQuery(true)
      }
    }
    showModalMembershipLevelQueryByTime()
  }, [])

  // The role of this function is to search the "addToCart" cookie and
  // send his data to the backend for add of the orderForm
  // and the same way validate if the use mobile and force reload when a change to a page with arrow back
  useEffect(() => {
    const checkAddToCartPending = () => {
      if (!isLoggedIn) return
      const recoverAddToCartPending = JSON.parse(Cookies.get('addToCart') || '[]')
      if (
        recoverAddToCartPending.length > 0 &&
        !queryClient.isMutating({ mutationKey: 'mutateOrderForm' })
      ) {
        handleMutate(recoverAddToCartPending)
      }
    }

    const validaReload = (event: { persisted: boolean }): void => {
      const historyTraversal = event.persisted ||
        (typeof window.performance != 'undefined' &&
          performance?.getEntriesByType('navigation')?.[0]?.['type'] === 'back_forward')

      if (historyTraversal) {
        window.location.reload()
      }
    }

    checkAddToCartPending()

    window.addEventListener('pageshow', validaReload)
    return () => {
      window.removeEventListener('pageshow', validaReload)
    }
  }, [
    isLoggedIn,
    queryClient.isFetching('orderForm')
  ])

  useEffect(() => {
    if (isLoading) return

    updateTagOrderForm({ orderForm: data || { noSession: true } })
  }, [data, isLoading])

  return (
    <Container backgroundColor={background} isWrap>
      {showModalRegisterClub && (
        <ModalRegisterClubWrapper handleToggle={handleToggleRegisterClub} />
      )}
      {showModalMembershipLevelQuery && (
        <ModalMembershipLevelQueryWrapper
          handleContinue={handleMembershipLevelQueryContinue}
          handleToggle={handleToggleMembershipLevelQuery}
          isBlockedBySecurity={blockedBySecurity}
          isLoading={isLoadingMembershipQueryLevel}
        />
      )}
      {showModalMembershipType && (
        <ModalMembershipTypeWrapper
          handleToggle={handleToggleMembershipType}
          membershipLevel={membershipType}
        />
      )}

      <HeaderBrowse />
      {children}
      <BigScreen>
        <Spacer.Horizontal backgroundColor={background} size={160} />
      </BigScreen>
      <SmallScreen>
        <Spacer.Horizontal backgroundColor={background} size={32} />
      </SmallScreen>
      {isLoggedIn &&
        <>
          <StoreLocatorComponent />
        </>
      }
      {isShowStockModal
        && <NoStockModal
          isOpen={false}
          onClick={handleCloseNoStockModal}
          warnings={messageNoStockModal.current}
        />
      }
      <Footer />
    </Container>
  )
}
