import { useMemo } from 'react'
import {
  Column,
  Container,
  Icon,
  Modal,
  RichText
} from '@smu-chile/pkg-unimarc-components'
import { useContentful, UseQueryResult } from '@smu-chile/pkg-unimarc-hooks'
import { Entry } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IContentful'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface TyCLoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TyCLoginModal = ({ isOpen, onClose }: TyCLoginModalProps) => {
  const { data } = useContentful({
    options: {
      content_type: 'terms-of-service',
      'fields.formatId': '5',
      'fields.name[in]': 'PP Web Alvi,Terminos'
    },
    reactQuery: {
      cacheTime: 0,
      enabled: true,
      retry: false
    }
  }) as UseQueryResult<{
    items: Entry[]
  }>

  const [primaryContent, secondaryContent] = useMemo(() => {
    if (data && data.items.length > 0) {
      return data.items.reverse().map((content) => {
        return [content.fields?.terms?.content]
      })
    }

    return []
  }, [data])

  return (
    <Modal
      fullScreen
      iconPadding='1.5em'
      isCloseIcon={false}
      isOpen={isOpen}
      padding='0'
    >
      <>
        <Container
          clickable='pointer'
          onClick={onClose}
          padding='1.5rem'
          position='absolute'
        >
          <Icon
            clickable='pointer'
            color={getGlobalStyle('--color-icons-gray')}
            customSize={26}
            name='Back'
          />
        </Container>

        <Column
          alignItems='center'
          gap={45}
          padding='4rem 2rem'
        >
          {
            primaryContent?.length > 0 && (
              <RichText richTextData={primaryContent} />
            )
          }

          {
            secondaryContent?.length > 0 && (
              <RichText richTextData={secondaryContent} />
            )
          }
        </Column>
      </>
    </Modal>
  )
}
