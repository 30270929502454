/**
 * Search product promotion from a promotion list by promotion name
 * @param {any} product - product data
 * @param {any[]} promotions - promotions data
 * @returns
 */
export function searchPromotion(product: unknown, promotions: unknown[]) {
  let promotion = null

  if (product && promotions) {
    const promotionId = /C(\d+)/.exec(product?.['promotion']?.name)
    if (promotionId) {
      const promotionTemp = promotions?.find((dataPromotion) => {
        return promotionId[0] == dataPromotion?.['campaignId']
      })

      if (promotionTemp) {
        promotion = {
          backgroundColor: promotionTemp?.['color'],
          color: promotionTemp?.['textColor'],
          label: promotionTemp?.['campaignName'],
          image: promotionTemp?.['campaignIcon']?.file?.url
        }
      }
    }
  }

  return promotion
}
