export const aislesProps = ({
  categoriesDataImage,
  isMobile,
  isVisableBanner,
  isVisibleAisles,
  offersMobile,
  resultAisles,
  setIsVisableBanner,
  setIsVisibleAisles
}) => {
  let urlBanner = ''

  const handleToggle = () => {
    setIsVisibleAisles(!isVisibleAisles)
  }

  const handleHoverCategory = (data) => {
    if (data?.banner) urlBanner = data?.banner
    setIsVisableBanner(Boolean(data?.banner))
  }

  const handleClickCategory = () => {
    setIsVisibleAisles(false)
  }

  const handleCategoryUrl = ({ url = '' }) => {
    if (/\.com\.br(.+)/.test(url))
      return `/category` + /\.com\.br(.+)/.exec(url)[1]

    return url
  }

  const dataAisles = Array.isArray(resultAisles?.data) && resultAisles?.data.map(item => {
    return {
      ...item,
      image: Array.isArray(categoriesDataImage) && categoriesDataImage.find(category => {
        return category?.idCategory == item?.id
      })?.categoryImage?.file?.url
    }
  }) || []
  // implement offers to first object
  if (isMobile) dataAisles.unshift({ name: 'Ofertas', ...offersMobile })

  return {
    dropDownAislesProps: {
      data: dataAisles,
      isVisableBanner,
      isVisibleImageAlvi: true,
      isVisibleAislesAlvi: true,
      urlBanner,
      onHoverCategory: handleHoverCategory,
      onClickCategory: handleClickCategory,
      getCategoryUrl: handleCategoryUrl
    },
    isVisibleDropDownAisles: isVisibleAisles,
    onClickAisles: handleToggle
  }
}