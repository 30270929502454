import { FlowId, IPostTryAuthorizeResponse } from '@smu-chile/pkg-unimarc-hooks'

export const checkClientNotFound = (data: IPostTryAuthorizeResponse) => {
  if (
    data.data?.flowId === FlowId.Register ||
    data.data?.flowId === FlowId.RegisterWithFiles
  ) {
    throw new Error('client-not-found')
  }
}
