import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { ImageBannerWeb } from 'shared/interfaces/Home'

/**
 * Search image banner with the specific alvi enabled level
 * @param imageBanners - list of image banners
 * @param level - specific image banner level
 * @returns
 */
export function searchImageBannerByLevel(imageBanners: Array<ImageBannerWeb>, level: string) {
  if (
    isValidArrayWithData(imageBanners) &&
    isValidArrayWithData([level])
  ) {
    return imageBanners.filter(imageBanner => {
      if (isValidArrayWithData(imageBanner.alviEnabledLevels)) {
        if (level == 'no level' || level == 'clubalvi' ) {
          return imageBanner.alviEnabledLevels.includes('club')
        }

        return imageBanner.alviEnabledLevels.includes(level)
      }

      return false
    })
  }

  return imageBanners
}
