import { ChangeEvent } from 'react'
import {
  Checkbox,
  Divider,
  Field,
  Link,
  PasswordInput,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface UpdateDocumentModalProps {
  previousPassword?: string;
  tycConfirm?: boolean;
  onCheckboxClick?: () => void;
  onInputChange?: (event?: ChangeEvent<HTMLInputElement>) => void;
  onLinkClick?: () => void;
}

export const UpdatePasswordConfirm = ({
  previousPassword,
  tycConfirm,
  onCheckboxClick,
  onInputChange,
  onLinkClick
}: UpdateDocumentModalProps) => {
  const handleCheckboxClick = () => {
    if (onCheckboxClick) onCheckboxClick()
  }

  const handleInputChange = (event?: ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) onInputChange(event)
  }

  const handleTyCLinkClick = () => {
    if (onLinkClick) onLinkClick()
  }

  return (
    <>
      <Field gap={4} label='Contraseña'>
        <PasswordInput
          onChange={handleInputChange}
          placeholder='Ingresa tu contraseña'
          textAlign='left'
          value={previousPassword}
        />

        <Divider color={getGlobalStyle('--color-alvi-neutral-gray')} verticalMargin={0} />
      </Field>

      <Row alignItems='center' gap={8}>
        <Checkbox
          iconColor='white'
          nameIcon='Check'
          onClick={handleCheckboxClick}
          site='alvi'
          type='checkbox'
          value={tycConfirm}
        />

        <Text
          fontSize='sm'
          isFlex={false}
          lineHeight='1'
          type='label'
        >
          Soy mayor de 18 años, he leído y aceptado los{' '}
          <Link
            href='#'
            onClick={handleTyCLinkClick}
            underline='none'
          >
            <Text
              clickable='pointer'
              color='blue'
              fontSize='sm'
              isFlex={false}
              lineHeight='1.5'
            >
              términos y condiciones y las políticas de privacidad.
            </Text>
          </Link>
        </Text>
      </Row>
    </>
  )
}
