import { useState } from 'react'
import {
  useEvents,
  useMobile,
  useProducts,
  useUpdateFromShelfAlvi,
  useUser
} from '@smu-chile/pkg-unimarc-hooks'

import { useProductsCarousel, useShelvesData } from '../helpers/useProductsCarousel'
import { ContentCarousel } from '../ContentCarousel'
export interface ProductCarouselProps {
  clusterData: object[],
  titleSizes: object
}

export const CarouselCollections = ({ clusterData, titleSizes }: ProductCarouselProps) => {
  // use
  const site = 'alvi'
  const { isMobile } = useMobile()
  const user = useUser()
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const productsCluster = useProducts({
    fq: clusterData?.['reference'],
    from: 0,
    to: 11,
    site
  })

  const { products } = productsCluster?.data || {}
  const {
    linkFontSize,
    dataPromotions,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    carouselHeaderPadding,
    spacerBelow,
    resultPromotions
  } = useProductsCarousel({ isMobile })

  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelfAlvi({ isOrderFormLoading, products, userId: user?.userId })

  const productsMemo = useShelvesData(shelvesData, dataPromotions, isMobile)

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm', callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })
  const carouselShelData = {
    itemsToShow,
    itemsToSlide,
    positionContainer,
    productsMemo,
    spacerBelow,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  }

  const isLoading: boolean = productsCluster?.isLoading ||
    resultPromotions?.isLoading || dataPromotions?.isLoading

  return (
    <ContentCarousel
      carouselHeaderPadding={carouselHeaderPadding}
      carouselShelData={carouselShelData}
      clusterData={clusterData}
      isLoading={isLoading}
      isMobile={isMobile}
      linkFontSize={linkFontSize}
      titleSizes={titleSizes}
    />
  )
}