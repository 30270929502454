import { IEntriesProps, useContentful } from '@smu-chile/pkg-unimarc-hooks'

interface IConnectionCFHeaderImages {
  dataHeaderImagesCF?:IEntriesProps;
}

const useConnectionCFHeaderImages = (): IConnectionCFHeaderImages => {
  const {
    data: dataHeaderImagesCF
  } = useContentful({
    id_contentful: `dataHeaderImages`,
    options: {
      'content_type': 'viewBlock',
      'fields.blockId[in]': 'nivelesMenuClubAlvi'
    },
    reactQuery: {
      enabled: true,
      retry: 3, staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  })

  return {
    dataHeaderImagesCF
  }
}

export { useConnectionCFHeaderImages }