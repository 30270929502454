import { DesktopModalWrapper, Column, Text, Picture } from '@smu-chile/pkg-unimarc-components'
import { BenefitsModalProps, BenefitsModalTestIds } from 'components/BenefitsModal'

export type BenefitsModalDesktopProps = BenefitsModalProps

export const BenefitsModalDesktop = ({
  description = 'Estos son los beneficios que tienes en tu nivel',
  imageSrc,
  isOpen,
  onClose,
  title = 'Mis beneficios'
}: BenefitsModalDesktopProps) => {
  return (
    <DesktopModalWrapper
      blockId={BenefitsModalTestIds.modal}
      bodyChildrenProps={{
        alignItems: 'center',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '24px 28px 0'
      }}
      hasfooter={false}
      headerCloseIcon={{
        default: true,
        name: 'CloseThin',
        sizes: '2xs'
      }}
      headerContainerProps={{
        padding: '22px'
      }}
      headerTitle={title}
      headerTitleProps={{
        id: BenefitsModalTestIds.title,
        lineHeight: '22.68px'
      }}
      modalConfigsProps={{
        isOpen: isOpen,
        toggle: onClose,
        toggleOutside: onClose,
        minHeight: '580px'
      }}
    >
      <Column alignItems='center'>
        <Text
          fontSize='lg'
          fontWeight='regular'
          id={BenefitsModalTestIds.description}
          lineHeight='20.16px'
          textAlign='center'
        >
          {description}
        </Text>

        <Picture
          height='auto'
          id={BenefitsModalTestIds.picture}
          src={imageSrc}
          width='100%'
        />
      </Column>
    </DesktopModalWrapper>
  )
}
