import { useEffect, useState } from 'react'
import {
  ButtonStatus,
  ShelfAlvi,
  QuantityButtonProps,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  productClickTag,
  shelvesImpression,
  useEvents,
  useUser,
  useUpdateFromShelfAlvi,
  useCart
} from '@smu-chile/pkg-unimarc-hooks'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { formatProductUrl } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/formatProductUrl'
import { statusPromotions } from '../../shared/helpers'
import { redirect } from '../../shared/utils/redirect'
import { ShelvesDataAlvi } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICart'
import { formatPromotion } from './helpers/formatPromotion'
import { searchPromotion } from './helpers/searchPromotion'
import { formatPriceList } from './helpers/formatPriceList'
import { formatQuantityButton } from './helpers/formatQuantityButton'
import { formatQuantityDisplay } from './helpers/formatQuantityDisplay'

export interface Data extends Product, AvailableProduct {
  buttonStatus?: ButtonStatus
  isDisabled?: boolean
  isLoading?: boolean
  quantity?: string | number
}

interface ShelvesProps {
  customWidthQuantityButton?: string
  dataPromotions?: unknown[]
  isMobile: boolean
  orientation?: 'horizontal' | 'vertical'
  padding?: string
  products: AvailableProduct[] | Product[] | Data[]
  quantityButtonProps?: Omit<QuantityButtonProps, 'value'>
  handleOnClick?: (props?: { itemId: string }) => void
  shelfWidth?: string
}

export const Shelves = ({
  dataPromotions,
  padding,
  products,
  shelfWidth = '200px'
}: ShelvesProps): React.ReactElement => {
  const { data } = useCart()
  const user = useUser()
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const {
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove,
    shelvesData
  } = useUpdateFromShelfAlvi({
    products,
    isOrderFormLoading,
    userId: user?.userId
  })
  const impressableShelves = []
  const site = 'Alvi'

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  useEffect(() => {
    if (impressableShelves.length === shelvesData?.length) {
      shelvesImpression({
        orderForm: data,
        shelves: impressableShelves,
        site: site,
        userId: user?.['userId']
      })
    }
  }, [impressableShelves, shelvesData, data, user])

  const handleCatchShelfRef = (product: ShelvesDataAlvi, index: number) => {return (ref: unknown) => {
    if (ref) {
      impressableShelves.push({
        position: index + 1,
        product,
        ref
      })
    }
  }}

  const handleShelfClick = (event: unknown) => {return () => {
    productClickTag({
      product: event['product'],
      site,
      userId: user?.['userId']
    })
  }}

  const handleShelfRedirect = (event: unknown) => {return () => {
    productClickTag({
      product: event['product'],
      site,
      userId: user?.['userId']
    })

    redirect(event['productUrl'])
  }}

  return (
    <Row
      gap={12}
      isWrap
      padding={padding}
    >
      {shelvesData &&
        shelvesData.map((product, index) => {
          const { isPromotion, price, ppum, offer } = statusPromotions(product)

          const productPriceList = formatPriceList(product)
          const productPromotion = formatPromotion(product)
          const productQuantityButton = formatQuantityButton(product, {
            handleAdd,
            handleChange,
            handleRemove
          })
          const productQuantityDisplay = formatQuantityDisplay(product)
          const productUrl = formatProductUrl(product.detailUrl)

          const overlapPromotion = searchPromotion(product, dataPromotions)

          return (
            <>
              <ShelfAlvi
                brand={product?.brand}
                catchShelfRef={handleCatchShelfRef(product, index)}
                format={product?.format}
                handleOnClick={handleOnClick}
                img={product?.images[0]}
                isPromotion={isPromotion}
                itemId={product?.itemId}
                key={product?.productId}
                offer={offer}
                onClickProduct={handleShelfClick({ product })}
                onRedirect={handleShelfRedirect({ product, productUrl })}
                ppum={`(${ppum})`}
                price={price}
                priceSteps={productPriceList}
                promotion={overlapPromotion || productPromotion}
                quantity={parseInt(`${product?.quantity}`)}
                quantityButtonProps={productQuantityButton}
                quantityDisplay={productQuantityDisplay}
                stock={product?.stock}
                title={product?.name}
                tooltipProps={product?.tooltipProps}
                url={productUrl}
                version={2}
                width={shelfWidth}
              />
            </>
          )
        })}
    </Row>
  )
}
