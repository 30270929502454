import { ReactNode } from 'react'
import { BigScreen, getBemId, SmallScreen } from '@smu-chile/pkg-unimarc-components/helpers'
import { BenefitsModalMobile } from './components/BenefitsModalMobile'
import { BenefitsModalDesktop } from './components/BenefitsModalDesktop'

export const BenefitsModalTestIds = {
  description: getBemId('benefitsModal', 'description'),
  modal: getBemId('benefitsModal', 'modal'),
  picture: getBemId('benefitsModal', 'picture'),
  title: getBemId('benefitsModal', 'title')
}

export type BenefitsModalProps = {
  description?: ReactNode
  imageSrc?: string
  isOpen?: boolean
  onClose?: VoidFunction
  title?: string
}

export const BenefitsModal = (props: BenefitsModalProps) => {
  return (
    <>
      <BigScreen>
        <BenefitsModalDesktop {...props} />
      </BigScreen>

      <SmallScreen>
        <BenefitsModalMobile {...props} />
      </SmallScreen>
    </>
  )
}
