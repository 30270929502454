import {
  Text,
  Column,
  Container,
  Row,
  Spacer,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { formatTextWithLineBreaks } from '@smu-chile/pkg-unimarc-components/shared/helpers'

export const ModalRegisterClubContent = (): React.ReactElement => {
  const blockId = 'modalRegisterClubContent'
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const imageWidth = isMobile ? '246px' : '185px'
  const imageHeight = isMobile ? '246px' : '185px'
  const maxWidthRow = isMobile ? '343px' : '240px'
  const modalMessage = isMobile
    ? 'Aún no eres parte del Club.\n¡Regístrate ahora y descubre los\nbeneficios y descuentos\nque tenemos para ti!'
    : 'Aún no eres parte del Club. ¡Regístrate ahora y descubre los\nbeneficios y descuentos que tenemos para ti!'
  return (
    <Container
      maxWidth='100%'
      position='relative'
      tagName='section'
    >
      <Column alignItems='center'>
        {/* welcome message */}
        <Row
          justifyContent='center'
          tagName='article'
        >
          <Text
            fontSize='xl'
            fontWeight='semibold'
          >
            Únete al Club Alvi
          </Text>
        </Row>
        <Spacer.Horizontal customSize={36} />
        {/* image membership type */}
        <Row
          justifyContent='center'
          maxWidth={maxWidthRow}
          tagName='article'
        >
          <Picture
            alt='registrate con el Club Alvi'
            height={imageHeight}
            src='/images/joinTheClub-new.webp'
            title='registrate con el Club Alvi'
            width={imageWidth}
          />
        </Row>
        <Spacer.Horizontal customSize={21} />
        {/* message */}
        <Row
          alignItems='center'
          id={getBemId(blockId, 'altiro-logo')}
          justifyContent='center'
          tagName='article'
          width='100%'
        >
          <Column alignItems='center'>
            <Text
              fontSize='lg'
              id={getBemId(blockId, 'welcome-text')}
              textAlign='center'
            >
              {formatTextWithLineBreaks(modalMessage)}
            </Text>
          </Column>
        </Row>
      </Column>
    </Container>
  )
}
