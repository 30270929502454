import { DeliveryMediumModal, DeliveryMediumModalProps } from '@smu-chile/pkg-unimarc-components'

export type DeliveryMediumModalBrowseProps = DeliveryMediumModalProps

export const DeliveryMediumModalBrowse = (props: DeliveryMediumModalBrowseProps) => {
  return (
    <DeliveryMediumModal
      minRutSize={8}
      optionsTitle='Quiero recibirla vía'
      site='alvi'
      subtitle='Ingresa tu Rut y te enviaremos la información para restablecer tu contraseña'
      title='¿Olvidaste tu contraseña?'
      {...props}
    />
  )
}
