import { useEffect } from 'react'

export const useAislesVisible = ({ isMobile, setIsVisibleAisles }) => {
  const menuContainerId = 'header__aisle_menu_button'
  const svgIconId = 'Menu'

  const checkIsInAislesMenuElement = (e, elementId) => {
    return (
      e.target?.id === elementId ||
      e.target?.firstChild?.id == elementId ||
      e.target?.parentNode?.id === elementId ||
      e.target?.parentNode?.parentNode?.id === elementId
    )
  }

  const checkIsInAislesMenu = (e) => {
    return (
      checkIsInAislesMenuElement(e, menuContainerId) ||
      checkIsInAislesMenuElement(e, svgIconId)
    )
  }

  useEffect(() => {
    const listener = (e) => {
      if (isMobile) return
      const isAisleMenu = checkIsInAislesMenu(e)
      if (!isAisleMenu) setIsVisibleAisles(false)
    }

    window.addEventListener('click', listener)

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [isMobile])
}
