import { ChangeEvent, useState } from 'react'
import {
  Button,
  Column,
  Container,
  Divider,
  Field,
  Icon,
  Modal,
  Notification,
  PasswordInput,
  Picture,
  Row,
  Text,
  Title,
  ValidationText
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IPostUserPasswordAlviResponse, useUpdatePasswordAlvi } from '@smu-chile/pkg-unimarc-hooks'
import { getValidationColor } from '../helpers'
import { TyCLoginModal } from '../TyCLoginModal'
import { UpdatePasswordConfirm } from './UpdatePasswordConfirm'

export interface UpdateDocumentModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onFailed?: () => void;
  onSuccess?: () => void;
}

export const UpdatePasswordModal = ({
  isOpen,
  onClose,
  onFailed,
  onSuccess
}: UpdateDocumentModalProps) => {
  const [isTyCLoginOpen, setTyCLoginOpen] = useState<boolean>(false)
  const [notificationShow, setNotificationShow] = useState<boolean>(false)
  const [previousPassword, setPreviousPassword] = useState<string>('')
  const [proposedPassword, setProposedPassword] = useState<string>('')
  const [tycConfirm, setTycConfirm] = useState<boolean>(false)

  const validation = {
    password: proposedPassword?.trim() !== '',
    passwordConfirm: previousPassword?.trim() !== '',
    passwordLowerCase: /[a-z]/.test(proposedPassword),
    passwordMinim: /.{8,}/.test(proposedPassword),
    passwordNumber: /\d/.test(proposedPassword),
    passwordSpecialCharacter: /\W/.test(proposedPassword),
    passwordUpperCase: /[A-Z]/.test(proposedPassword),
    get result() {
      return (
        this.password &&
        this.passwordConfirm &&
        this.passwordLowerCase &&
        this.passwordMinim &&
        this.passwordNumber &&
        this.passwordSpecialCharacter &&
        this.passwordUpperCase &&
        tycConfirm
      )
    }
  }

  const {
    isFetching: updatePasswordFetching,
    refetch: updatePasswordFetch
  } = useUpdatePasswordAlvi({
    previousPassword,
    proposedPassword,
    reactQuery: {
      enabled: false,
      onSuccess: (result) => {
        handleUpdatePasswordSuccess(result)
      }
    }
  })

  const buttonStatusDefault = validation.result ? 'initial' : 'disabled'
  const buttonStatus = updatePasswordFetching ? 'loading' : buttonStatusDefault

  const handleButtonConfirmClick = async () => {
    await updatePasswordFetch()
  }

  const handleCheckboxClick = () => {
    setTycConfirm(!tycConfirm)
  }

  const handleInputChange = (name: string) => {
    return (event?: ChangeEvent<HTMLInputElement>) => {
      if (event) {
        switch (name) {
          case 'password-previous':
            setPreviousPassword(event.target.value)
            break
          case 'password-proposal':
            setProposedPassword(event.target.value)
            break
        }
      }
    }
  }

  const handleNotificationClose = () => {
    setNotificationShow(false)
  }

  const handleTyCLinkClick = () => {
    setTyCLoginOpen(true)
  }

  const handleTyCLoginClose = () => {
    setTyCLoginOpen(false)
  }

  const handleUpdatePasswordSuccess = ({ status }: IPostUserPasswordAlviResponse) => {
    if (status === 204) {
      if (onSuccess) onSuccess()
      return
    }

    resetAllPassword()
    setNotificationShow(true)

    if (onFailed) onFailed()
  }

  const resetAllPassword = () => {
    setPreviousPassword('')
    setProposedPassword('')
    setTycConfirm(false)
  }

  return (
    <>
      <BigScreen>
        <Modal
          iconPadding='1.5em'
          isOpen={isOpen}
          maxWidth='820px'
          padding='0'
          toggle={onClose}
        >
          <Row>
            <Picture
              height='547px'
              lineHeight={0}
              objectFit='cover'
              src='https://images.ctfassets.net/un6yvtd6uq5z/6kPuN2QdLOVRYGYZN4dINL/0e3fb0948cdaab67608f8b07e404d097/Frame_7934.png'
              width='400px'
            />

            <Column justifyContent='between' minHeight='500px'>
              <Column gap={16} padding='2.5em'>
                <Container position='relative'>
                  <Notification
                    align='top-center'
                    message='Error al actualizar su contraseña, vuelva a intentarlo nuevamente en unos minutos.'
                    onClose={handleNotificationClose}
                    show={notificationShow}
                    showCloseIcon
                    site='alvi'
                  />
                </Container>

                <Column gap={8}>
                  <Title fontWeight='semibold'>
                    Actualiza tu contraseña
                  </Title>

                  <Text fontSize='md'>
                    Por tu seguridad necesitamos que actualices tu contraseña cumpliendo con lo requerido.
                  </Text>
                </Column>

                <Field gap={4} label='Nueva contraseña'>
                  <Column gap='4px'>
                    <PasswordInput
                      onChange={handleInputChange('password-proposal')}
                      placeholder='Ingresa tu respuesta...'
                      textAlign='left'
                      value={proposedPassword}
                    />

                    <Divider color={getGlobalStyle('--color-alvi-neutral-gray')} verticalMargin={0} />

                    <Container margin='2px 0'>
                      <Text fontSize='sm'>
                        Debes usar al menos:
                      </Text>
                    </Container>

                    <Column gap='8px'>
                      <Row justifyContent='start'>
                        <Container maxWidth='40%'>
                          <ValidationText
                            color={getValidationColor(validation.passwordLowerCase)}
                            iconSize={7}
                            text='1 minúscula (a-z)'
                          />
                        </Container>

                        <ValidationText
                          color={getValidationColor(validation.passwordSpecialCharacter)}
                          iconSize={7}
                          text='1 carácter especial (Ej: *·#%)'
                        />
                      </Row>

                      <Row justifyContent='start'>
                        <Container maxWidth='40%'>
                          <ValidationText
                            color={getValidationColor(validation.passwordNumber)}
                            iconSize={7}
                            text='1 número (0-9)'
                          />
                        </Container>

                        <ValidationText
                          color={getValidationColor(validation.passwordUpperCase)}
                          iconSize={7}
                          text='1 mayúscula (A-Z)'
                        />
                      </Row>

                      <Row justifyContent='start'>
                        <Container maxWidth='40%'>
                          <ValidationText
                            color={getValidationColor(validation.passwordMinim)}
                            iconSize={7}
                            text='Mínimo 8 caracteres'
                          />
                        </Container>
                      </Row>
                    </Column>
                  </Column>
                </Field>

                <UpdatePasswordConfirm
                  onCheckboxClick={handleCheckboxClick}
                  onInputChange={handleInputChange('password-previous')}
                  onLinkClick={handleTyCLinkClick}
                  previousPassword={previousPassword}
                  tycConfirm={tycConfirm}
                />
              </Column>

              <Container justifyContent='center' padding='0 2.5em 1.5em'>
                <Button
                  borderRadius='24px'
                  fontSize='14px'
                  fontWeight='500'
                  fullWidth
                  label='Confirmar nueva contraseña'
                  maxWidth='300px'
                  onClick={handleButtonConfirmClick}
                  site='alvi'
                  size='large'
                  status={buttonStatus}
                />
              </Container>
            </Column>
          </Row>
        </Modal>
      </BigScreen>

      <SmallScreen>
        <Modal
          fullScreen
          iconPadding='1.5em'
          isOpen={isOpen}
          padding='0'
          toggle={onClose}
        >
          <Column customHeight='calc(100vh - 4rem)' justifyContent='between'>
            <Column overflow='auto'>
              <Column gap={24} padding='2rem'>
                <Container position='relative'>
                  <Notification
                    align='top-center'
                    message='Error al actualizar su contraseña, vuelva a intentarlo nuevamente en unos minutos.'
                    onClose={handleNotificationClose}
                    show={notificationShow}
                    showCloseIcon
                    site='alvi'
                  />
                </Container>

                <Column gap={8}>
                  <Row gap={8}>
                    <Container maxWidth='24px'>
                      <Icon
                        color={getGlobalStyle('--color-text-warning')}
                        customSize={24}
                        name='AlviAlertIcon'
                      />
                    </Container>

                    <Text fontSize='xl' fontWeight='semibold'>
                      Actualiza tu contraseña
                    </Text>
                  </Row>

                  <Text fontSize='md'>
                    Por tu seguridad necesitamos que actualices tu contraseña cumpliendo con lo requerido.
                  </Text>
                </Column>

                <Field gap={4} label='Nueva contraseña'>
                  <Column gap='4px'>
                    <PasswordInput
                      onChange={handleInputChange('password-proposal')}
                      placeholder='Ingresa tu nueva contraseña...'
                      textAlign='left'
                      value={proposedPassword}
                    />

                    <Divider color={getGlobalStyle('--color-alvi-neutral-gray')} verticalMargin={0} />

                    <Container margin='8px 0 2px'>
                      <Text fontSize='sm'>
                        Debes usar al menos:
                      </Text>
                    </Container>

                    <Column gap='8px'>
                      <ValidationText
                        color={getValidationColor(validation.passwordLowerCase)}
                        iconSize={7}
                        text='1 minúscula (a-z)'
                      />

                      <ValidationText
                        color={getValidationColor(validation.passwordSpecialCharacter)}
                        iconSize={7}
                        text='1 carácter especial (Ej: *·#%)'
                      />

                      <ValidationText
                        color={getValidationColor(validation.passwordNumber)}
                        iconSize={7}
                        text='1 número (0-9)'
                      />

                      <ValidationText
                        color={getValidationColor(validation.passwordUpperCase)}
                        iconSize={7}
                        text='1 mayúscula (A-Z)'
                      />
                      <ValidationText
                        color={getValidationColor(validation.passwordMinim)}
                        iconSize={7}
                        text='Mínimo 8 caracteres'
                      />
                    </Column>
                  </Column>
                </Field>

                <UpdatePasswordConfirm
                  onCheckboxClick={handleCheckboxClick}
                  onInputChange={handleInputChange('password-previous')}
                  onLinkClick={handleTyCLinkClick}
                  previousPassword={previousPassword}
                  tycConfirm={tycConfirm}
                />
              </Column>
            </Column>

            <Container
              boxShadow={getGlobalStyle('--box-shadow-xs')}
              justifyContent='center'
              padding='.75rem 2.5rem 2.5rem'
            >
              <Button
                borderRadius='24px'
                fontSize='14px'
                fontWeight='500'
                fullWidth
                label='Confirmar nueva contraseña'
                maxWidth='300px'
                onClick={handleButtonConfirmClick}
                site='alvi'
                size='large'
                status={buttonStatus}
              />
            </Container>
          </Column>

          <TyCLoginModal
            isOpen={isTyCLoginOpen}
            onClose={handleTyCLoginClose}
          />
        </Modal>
      </SmallScreen>
    </>
  )
}
