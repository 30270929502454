import {
  Container,
  Icon,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { goToHome } from '@smu-chile/pkg-unimarc-hooks'
interface LayoutProps {
  children: React.ReactNode
}

export const HeaderError = (props: LayoutProps) => {
  const handleGoToHome = () => { return goToHome() }
  return (
    <>
      <BigScreen>
        <Row maxWidth='1440px'>
          <Container
            background={getGlobalStyle('--color-alvi-primary-blue-header-gradient')}
            customHeight='80px'
            justifyContent='center'
            position='fixed'
          >
            <Icon
              color={getGlobalStyle('--color-alvi-text-primary')}
              customSize={80}
              name='LogoAlvi'
              onClick={() => { return handleGoToHome() }}
            />
          </Container>
        </Row>
      </BigScreen>
      <SmallScreen>
        <Row>
          <Container
            background={getGlobalStyle('--color-alvi-primary-blue-header-gradient')}
            customHeight='75px'
            justifyContent='center'
            position='fixed'
          >
            <Icon
              color={getGlobalStyle('--color-alvi-text-primary')}
              customSize={69}
              name='LogoAlvi'
              onClick={() => { return handleGoToHome() }}
            />
          </Container>
        </Row>
      </SmallScreen>
      {props.children}
    </>
  )
}