import Script from 'next/script'
import { useEffect, useState } from 'react'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@smu-chile/pkg-unimarc-hooks/reactQuery'
import { Layout } from 'components/Layout'
import { HeaderError } from 'components/ErrorPage/HeaderError'
import { ReactQueryDevtools } from '@smu-chile/pkg-unimarc-hooks/reactQuery/devtools'
import { AppPropsWithLayout } from 'shared/interfaces/Layout'
import '@smu-chile/pkg-unimarc-components/assets/styles/globals.css'
import '@smu-chile/pkg-unimarc-components/assets/styles/tokens.css'
import '../styles/globals.css'
import ErrorBoundary from 'components/ErrorBoundary'
import { AuthBrowse } from 'components/AuthBrowse'

const MyApp = ({ Component, pageProps }: AppPropsWithLayout): React.ReactElement => {
  const componentDisplayName = Component.displayName
  const getLayout = Component.getLayout ?? ((page) => { return <Layout>{page}</Layout> })
  const [queryClient] = useState(() => { return new QueryClient() })
  const [rerender, setRerender] = useState(false)
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const width = isMobile ? innerWidth : 1280
  useEffect(() => {
    const validateWidth = () => {
      if (isMobile) {
        setRerender(!rerender)
      }
    }

    validateWidth()
  }, [innerWidth])

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places,geometry`}
        strategy='beforeInteractive'
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `var $jscomp=$jscomp||{};$jscomp.scope={};$jscomp.arrayIteratorImpl=function(g){var l=0;return function(){return l<g.length?{done:!1,value:g[l++]}:{done:!0}}};$jscomp.arrayIterator=function(g){return{next:$jscomp.arrayIteratorImpl(g)}};$jscomp.makeIterator=function(g){var l="undefined"!=typeof Symbol&&Symbol.iterator&&g[Symbol.iterator];return l?l.call(g):$jscomp.arrayIterator(g)};
            var OKTS=OKTS||function(g,l){function p(){var c=location.href,b=r(c),a=document.getElementById("OKTS_div"),e=0;if(a)e=a.getAttribute("data-ean")||0;else{var f=document.body;(new MutationObserver(function(m,n){for(var k=$jscomp.makeIterator(m),d=k.next();!d.done;d=k.next())if(d=d.value,"childList"===d.type){d=$jscomp.makeIterator(d.addedNodes);for(var h=d.next();!h.done;h=d.next())h=h.value,"DIV"==h.nodeName&&"undefined"!=typeof h.id&&"OKTS_div"==h.id&&OKTS.load_content()}})).observe(f,{childList:!0,
            subtree:!0})}e&&"0"!=e&&(OKTS.global_vars["okts_"+b]?(c=JSON.parse(OKTS.global_vars["okts_"+b]),c.css&&(e=document.getElementsByTagName("head")[0],f=document.createElement("link"),f.rel="stylesheet",f.type="text/css",f.href=c.css,e.appendChild(f)),a.innerHTML=c.html,OKTS.adjust_width()):(a=new XMLHttpRequest,a.onreadystatechange=function(){4==this.readyState&&200==this.status&&""!=this.responseText&&(OKTS.global_vars["okts_"+b]=this.responseText,p())},a.open("GET","https://api.okto.shop/snippet_v1/?url="+
            encodeURIComponent(c)+"&ean="+e,!0),a.send()))}function q(){for(var c=document.getElementById("OKTS_div_center"),b=document.getElementsByClassName("element_div"),a=0;a<b.length;a++)c.appendChild(b[a])}function r(c){return btoa(encodeURIComponent(c).replace(/%([0-9A-F]{2})/g,function(b,a){return String.fromCharCode("0x"+a)}))}return{global_vars:{},on_load:function(c){g.onresize=OKTS.adjust_width;OKTS.load_content()},load_content:p,adjust_width:function(){var c=document.getElementById("OKTS_div"),b=
            document.getElementById("OKTS_div_left"),a=document.getElementById("OKTS_div_right");if(c&&b&&a){c=c.offsetWidth;var e=document.getElementById("OKTS_div_ingredients"),f=document.getElementById("OKTS_div_traces_allergens"),m=document.getElementById("OKTS_div_certificates"),n=document.getElementById("OKTS_div_warnings"),k=document.getElementById("OKTS_div_table"),d=document.getElementById("OKTS_div_disclaimer"),h=document.getElementById("OKTS_div_logo");700<c?(q(),e&&b.appendChild(e),f&&b.appendChild(f),
            m&&b.appendChild(m),d&&b.appendChild(d),n&&a.appendChild(n),k&&a.appendChild(k)):(q(),e&&b.appendChild(e),f&&b.appendChild(f),m&&b.appendChild(m),n&&b.appendChild(n),k&&a.appendChild(k),d&&a.appendChild(d));h&&a.appendChild(h)}}}}(this);if("complete"===document.readyState)OKTS.on_load();
            window.addEventListener?(window.addEventListener("load",OKTS.on_load,!1),window.addEventListener("popstate",OKTS.on_load,!1)):window.attachEvent&&(window.attachEvent("onload",OKTS.on_load),window.attachEvent("onpopstate",OKTS.on_load));
          `
        }}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', 'GTM-MZT85Q3');
        `
        }}
        strategy='afterInteractive'
      />
      <ResponsiveContext.Provider value={{ width }} >
        <QueryClientProvider client={queryClient}>
          <AuthBrowse />
          {getLayout(
            <Hydrate state={pageProps.dehydratedState}>
              <ErrorBoundary inComponent={componentDisplayName}>
                <Component {...{ ...pageProps, isMobile }} />
              </ErrorBoundary>
            </Hydrate>
          )}
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ResponsiveContext.Provider>
    </>
  )
}
export const ErrorLayout = ({ children }: { children: React.ReactNode }) => { return <HeaderError>{children}</HeaderError> }

export default MyApp
