import { QuantityButtonProps, ShelvesDataAlvi } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICart'

/**
 * Format product price steps to shelf price list
 * @param {unknown} product - product data
 * @returns
 */
export function formatQuantityButton(product: ShelvesDataAlvi, additional?: QuantityButtonProps) {
  const initial = {
    handleAdd: () => { return },
    handleRemove: () => { return }
  }

  if (product.quantityButtonProps) {
    const [firstSeller] = product?.sellers ?? []
    const maxQuantity = product?.cartLimit > 0 ? product?.cartLimit : firstSeller?.availableQuantity

    return {
      ...initial,
      ...product.quantityButtonProps,
      ...additional,
      maxQuantity
    }
  }

  return initial
}
