import { useRouter } from 'next/router'
import { Container, ErrorMessage } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'

export const ErrorPageV2 = () => {
  const router = useRouter()
  const images = useContentful({
    options: {
      [`sys.id`]: '2qi6lG9kQimgrGumcZNjTz'
    },
    reactQuery: {
      queryKey: 'errorPageImages'
    },
    type: 'assets'
  })

  const handleErrorMessageButtonClick = () => {
    return router.reload()
  }

  const [image] = images.data?.['items'] ?? []

  return (
    <Container
      alignItems='stretch'
      backgroundColor={getGlobalStyle('--color-base-white')}
      flexGrow={1}
    >
      <ErrorMessage
        buttonLabel='Recargar'
        errorCode='CRT-01'
        errorImage={{
          img: image?.fields?.file?.url,
          height: {
            desktop: '200',
            mobile: '170'
          },
          width: {
            desktop: '200',
            mobile: '170'
          }
        }}
        onButtonClick={handleErrorMessageButtonClick}
        site='alvi'
        text={
          <>
            Tuvimos problemas para visualizar tu nivel, <br />
            por favor recarga.
          </>
        }
        title='¡Ya volvemos!'
      />
    </Container>
  )
}
