import { ChangeEvent, useMemo, useState } from 'react'
import {
  Column,
  Container,
  Icon,
  Link,
  LoginFormDirty,
  LoginFormError,
  LoginFormValues,
  LoginModal,
  LoginModalProps,
  Notification,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { formatRut, validateRut } from '@smu-chile/pkg-unimarc-components/utils/formatRut'
import {
  getRegisterUrl,
  postAlviLogin,
  useMobile,
  useTryAuthorize
} from '@smu-chile/pkg-unimarc-hooks'
import HttpError from '@smu-chile/pkg-unimarc-hooks/shared/errors/HttpError'
import { useRouter } from 'next/router'
import { checkClientNotFound, useLoginModalBrowseTries } from './helpers'
import { UpdateDocumentModal } from './UpdateDocumentModal'
import { UpdatePasswordModal } from './UpdatePasswordModal'
import { FailedLoginModal } from './FailedLoginModal'
import { cleanRut } from 'components/SSOLayout/SignUpForm/helpers/cleanRut'
import { LoginTooltip } from './LoginTooltip'

export interface LoginModalBrowseProps extends LoginModalProps {
  imageSrc: string;
  onUpdateDocumentSuccess?: () => void;
  onUpdatePasswordSuccess?: () => void;
}

interface LoginFormProps {
  dirty?: LoginFormDirty,
  errors?: LoginFormError
  loading?: boolean
}

const MAX_LOGIN_TRIES = 4
const LOGIN_RETRY_TIME_MS = 10_000

export const LoginModalBrowse = ({
  imageSrc,
  onClose,
  onUpdateDocumentSuccess,
  onUpdatePasswordSuccess,
  ...props
}: LoginModalBrowseProps): React.ReactElement => {
  const router = useRouter()
  const [formValues, setFormValues] = useState<LoginFormValues>({
    rut: '',
    password: ''
  })

  const { isMobile } = useMobile()

  const [isFailedLoginOpen, setIsFailedLoginOpen] = useState<boolean>(false)
  const [isUpdateDocumentOpen, setIsUpdateDocumentOpen] = useState<boolean>(false)
  const [isUpdatePasswordOpen, setIsUpdatePasswordOpen] = useState<boolean>(false)
  const [isUpdatePasswordNotificationOpen, setIsUpdatePasswordNotificationOpen] = useState<boolean>(false)

  const [formState, setFormState] = useState<LoginFormProps>({
    dirty: {},
    errors: {}
  })

  const [infoMessage, setInfoMessage] = useState<React.ReactNode>(undefined)
  const [errorMessageId, setErrorMessageId] = useState('')

  const isLoginOpen = props.isOpen && !isUpdateDocumentOpen && !isUpdatePasswordOpen

  const { tick: tickLoginTry } = useLoginModalBrowseTries({ limit: MAX_LOGIN_TRIES, retry: LOGIN_RETRY_TIME_MS })

  const { refetch: tryAuthorize } = useTryAuthorize({
    reactQuery: {
      enabled: false
    },
    rut: formValues.rut
  })

  const disableSubmit = useMemo(() => {
    if (
      formState.errors.rut ||
      formState.loading ||
      formValues.rut === '' ||
      formValues.password === ''
    ) {
      return true
    }

    return false
  }, [
    formState.errors,
    formState.loading,
    formValues.password,
    formValues.rut
  ])

  const setLoading = (value: boolean) => {
    setFormState(prev => {
      return {
        ...prev,
        loading: value
      }
    })
  }

  const resetFormState = () => {
    const newFormState = {
      dirty: {},
      errors: {}
    }
    setFormState(newFormState)
    return newFormState
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      const { name } = event.target
      let { value } = event.target

      if (name === 'rut') {
        value = cleanRut(value)
        value = formatRut(value)

        setFormState({
          ...formState,
          errors: {
            [name]:
              value.trim() !== ''
                ? !validateRut(value) && 'Ingrese un RUT válido.'
                : null
          }
        })
      }

      setFormValues({
        ...formValues,
        [name]: value
      })
    }

    if (!formState.dirty[event.target.name]) {
      setFormState({
        ...formState,
        dirty: {
          ...formState.dirty,
          [event.target.name]: true
        }
      })
    }
  }

  const handleSubmit = async () => {
    resetFormState()
    setLoading(true)

    let tic = 1

    try {
      const { data } = await tryAuthorize()

      checkClientNotFound(data)

      tic = tickLoginTry()

      const {
        data: loginData,
        status: loginStatus,
        statusText: loginStatusText
      } = await postAlviLogin(formValues.rut, formValues.password)

      if (loginStatus >= 300) {
        throw new HttpError(loginStatusText, loginStatus, loginData)
      }

      switch (loginData.flowId) {
        case '06':
          setIsUpdateDocumentOpen(true)
          break
        case '09':
          setIsUpdatePasswordOpen(true)
          break
        default:
          await router.push({ query: {} }, undefined)
          router.reload()
          break
      }
      await onClose?.()
    } catch (error) {
      if (error.message === 'client-not-found') {
        setErrorMessageId('client-not-found-message')
        setLoading(false)
        return
      }

      switch (error?.status) {
        case 429:
          setErrorMessageId('block-login-tries-message')
          break
        case 403:
          if (isMobile) {
            setIsFailedLoginOpen(true)
            if (onClose) onClose()
          } else {
            setInfoMessage('Ésta cuenta se encuentra bloqueada, por favor acércate a una de nuestras sucursales para activar tu cuenta')
          }
          break
        default:
          setTickErrorMessage(tic)
          break
      }
    }

    setLoading(false)
  }

  const setTickErrorMessage = (tic: number) => {
    if (tic < 3) {
      setErrorMessageId('invalid-credential-message')
    } else if (tic < 4) {
      setErrorMessageId('three-login-tries-message')
    } else {
      setErrorMessageId('block-login-tries-message')
    }
  }

  const handleClose = () => {
    resetFormValue()
    resetFormState()

    if (onClose) onClose()
  }

  const handleFailedLoginClose = () => {
    setIsFailedLoginOpen(false)
  }

  const handleNotificationClose = () => {
    setInfoMessage(null)
  }

  const handleUpdateDocumentClose = async () => {
    setIsUpdateDocumentOpen(false)
    await reloadRoute()

    if (onClose) onClose()
  }

  const handleUpdateDocumentSuccess = async () => {
    setIsUpdateDocumentOpen(false)

    if (onClose) onClose()
    if (onUpdateDocumentSuccess) onUpdateDocumentSuccess()
  }

  const handleUpdatePasswordClose = async () => {
    setIsUpdatePasswordOpen(false)
    await reloadRoute()

    if (onClose) onClose()
  }

  const handleUpdatePasswordNotificationClose = () => {
    setIsUpdatePasswordNotificationOpen(false)
  }

  const handleUpdatePasswordSuccess = async () => {
    setIsUpdatePasswordOpen(false)
    setIsUpdatePasswordNotificationOpen(true)

    if (onClose) onClose()
    if (onUpdatePasswordSuccess) onUpdatePasswordSuccess()
  }

  const reloadRoute = async () => {
    await router.push('/')
    router.reload()
  }

  const resetFormValue = () => {
    setFormValues({
      password: '',
      rut: ''
    })
  }

  const handleLoginModalGuestClick = () => {
    if (onClose) onClose()
  }

  const handleLoginModalRegisterClick = () => {
    window.location.href = getRegisterUrl()
  }

  const handleLoginTooltipClose = () => {
    setErrorMessageId('')
  }

  return (
    <>
      <FailedLoginModal
        isOpen={isFailedLoginOpen}
        onButtonClick={handleFailedLoginClose}
        onClose={handleFailedLoginClose}
      />

      <UpdateDocumentModal
        isOpen={isUpdateDocumentOpen}
        onClose={handleUpdateDocumentClose}
        onSuccess={handleUpdateDocumentSuccess}
      />

      <UpdatePasswordModal
        isOpen={isUpdatePasswordOpen}
        onClose={handleUpdatePasswordClose}
        onSuccess={handleUpdatePasswordSuccess}
      />

      <Notification
        margin='5rem 0'
        message='Tu contraseña se actualizó con éxito'
        onClose={handleUpdatePasswordNotificationClose}
        padding='.75rem .5rem'
        show={isUpdatePasswordNotificationOpen}
        showCloseIcon
        site='alvi'
      />

      <LoginModal
        {...formState}
        {...props}
        disableSubmit={disableSubmit}
        imageSrc={imageSrc}
        infoMessage={infoMessage}
        isOpen={isLoginOpen}
        maxWidth='862px'
        onChange={handleChange}
        onClose={handleClose}
        onGuestClick={handleLoginModalGuestClick}
        onNotificationClose={handleNotificationClose}
        onRegisterClick={handleLoginModalRegisterClick}
        onSubmit={handleSubmit}
        prependSlot={
          <>
            {errorMessageId !== '' && (
              <LoginTooltip
                customChildren={
                  <Container>
                    <Row
                      alignItems='center'
                      gap={8}
                    >
                      <Column maxWidth='max-content'>
                        <Icon
                          color='var(--color-feedback-error-light)'
                          customSize={20}
                          name='InfoFill'
                        />
                      </Column>
                      <Column margin='0 10px 0 0'>
                        {errorMessageId === 'not-found-user-message' && (
                          <>
                            <Text
                              color='black'
                              display='block'
                              fontSize='md'
                              fontWeight='medium'
                            >
                              Usuario no registrado, te invitamos a hacerlo{' '}
                              <Link
                                color='black'
                                fontSize='md'
                                fontWeight='semibold'
                                href='#'
                                underline='always'
                              >
                                aquí
                              </Link>
                              .
                            </Text>
                          </>
                        )}
                        {errorMessageId === 'invalid-credential-message' && (
                          <Text
                            color='black'
                            display='block'
                            fontSize='md'
                            fontWeight='medium'
                          >
                            Tu RUT o contraseña son incorrectos. Por favor,
                            intenta de nuevo.
                          </Text>
                        )}
                        {errorMessageId === 'three-login-tries-message' && (
                          <Text
                            color='black'
                            display='block'
                            fontSize='md'
                            fontWeight='medium'
                          >
                            ¿No recuerdas tu contraseña? Recupera tu contraseña{' '}
                            <Link
                              color='black'
                              fontSize='md'
                              fontWeight='semibold'
                              href='#'
                              onClick={props.onForgotPasswordClick}
                              underline='always'
                            >
                              aquí
                            </Link>{' '}
                            o presionando “Olvidé mi contraseña”.
                          </Text>
                        )}
                        {errorMessageId === 'block-login-tries-message' && (
                          <Text
                            color='black'
                            display='block'
                            fontSize='md'
                            fontWeight='medium'
                          >
                            Has superado la cantidad máxima de intentos.
                            Recupera tu cuenta{' '}
                            <Link
                              color='black'
                              fontSize='md'
                              fontWeight='semibold'
                              href='#'
                              onClick={props.onForgotPasswordClick}
                              underline='always'
                            >
                              aquí
                            </Link>
                            .
                          </Text>
                        )}
                        {errorMessageId === 'client-not-found-message' && (
                          <Text
                            color='black'
                            display='block'
                            fontSize='md'
                            fontWeight='medium'
                          >
                            Usuario no registrado, te invitamos a hacerlo{' '}
                            <Link
                              color='black'
                              fontSize='md'
                              fontWeight='semibold'
                              href={getRegisterUrl()}
                              onClick={props.onRegisterClick}
                              underline='always'
                            >
                              aquí
                            </Link>
                            .
                          </Text>
                        )}
                      </Column>
                    </Row>
                  </Container>
                }
                onClose={handleLoginTooltipClose}
                variant='error'
              />
            )}
          </>
        }
        site='alvi'
        values={formValues}
      />
    </>
  )
}