import {
  ChangeEvent,
  useRef,
  useState
} from 'react'
import {
  ButtonStatus,
  Column,
  Container,
  Divider,
  Icon,
  InputAdornment,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export interface UpdateDocumentModalProps {
  onChange?: (file?: File) => void;
}

export const UpdateDocumentInput = ({ onChange }: UpdateDocumentModalProps) => {
  const [file, setFile] = useState<File>(null)
  const [fileBackground, setFileBackground] = useState<string>(null)
  const [fileName, setFileName] = useState<string>(null)
  const [status, setStatus] = useState<ButtonStatus>('disabled')

  const inputFile = useRef<HTMLInputElement>()

  const handleIconRemoveClick = () => {
    if (status !== 'loading') {
      setFileBackground(null)
      setFileName('')
      setFile(null)
      setStatus('disabled')
      inputFile.current.value = ''
    }
  }

  const handleIconUploadClick = () => {
    if (status !== 'loading') {
      if (inputFile.current) {
        inputFile.current.click()
      }
    }
  }

  const handleInputFileChange = (event?: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (files.length > 0) {
      const file = files.item(0)

      setFileBackground(`url(${URL.createObjectURL(file)}) center center / cover no-repeat`)
      setFileName(file.name)
      setFile(file)
      setStatus('initial')

      if (onChange) onChange(file)
    }
  }

  return (
    <>
      <Column gap={4}>
        <InputAdornment
          inputAdornmentEnd={
            <Icon
              clickable='pointer'
              color='#1f2023'
              customSize={20}
              name='Upload'
              onClick={handleIconUploadClick}
            />
          }
          onClick={handleIconUploadClick}
          placeholder='Sube el documento comercial'
          readOnly
          value={fileName}
        />

        <input
          accept='image/png, image/jpeg'
          hidden
          name='document'
          onChange={handleInputFileChange}
          ref={inputFile}
          type='file'
        />

        <Divider color={getGlobalStyle('--color-alvi-neutral-gray')} verticalMargin={0} />
      </Column>

      <Column gap={16}>
        {
          file && (
            <Row>
              <Container
                background={fileBackground}
                borderRadius='2px'
                maxWidth='100px'
                padding='30px'
              >
                <Container
                  backgroundColor={getGlobalStyle('--color-background-white-opacity')}
                  borderRadius='20px'
                  clickable='pointer'
                  onClick={handleIconRemoveClick}
                  padding='10px'
                >
                  <Icon
                    clickable='pointer'
                    color={getGlobalStyle('--color-alvi-icons-raisin-black')}
                    customSize={20}
                    name='TrashBold'
                  />
                </Container>
              </Container>
            </Row>
          )
        }

        <Row gap='18px'>
          <Container width={20}>
            <Icon
              color='black'
              customSize={20}
              name='InfoFill'
            />
          </Container>

          <Text
            color='gray'
            fontSize='md'
            lineHeight='16px'
          >
            Recuerda tener el documento comercial o una foto de este, que acredita el tipo de
            comerciante que eres.
          </Text>
        </Row>
      </Column>
    </>
  )
}
