import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { Backbone } from '@smu-chile/pkg-unimarc-components'
import {
  AddressTypeProp,
  Items,
  simulateCart
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

interface MapProps {
  addressTypeToSend: AddressTypeProp,
  errorAddress: boolean;
  height?: number;
  location: { latMap: number; lngMap: number; };
  productDummy: Items[];
  cleanCoordsError?: () => void;
  coordsError: (error: string, type: string) => void;
  setNewLocation: (lat: number, lng: number) => void;
}

export const Map = ({
  addressTypeToSend,
  errorAddress,
  height = 180,
  location,
  productDummy,
  cleanCoordsError,
  coordsError,
  setNewLocation
}: MapProps): React.ReactElement => {

  const pin = async (coords) => {

    const validateCoverage = await simulateCart({
      country: 'CHL',
      geoCoordinates: [coords.lng(), coords.lat()],
      items: productDummy,
      addressType: addressTypeToSend
    })

    setNewLocation(coords.lat(), coords.lng())
    if (!validateCoverage?.slas) {
      coordsError('Lo sentimos, tuvimos un error al cargar tu información por favor intenta de nuevo.', 'message')
      cleanCoordsError()
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY
  })

  if (!isLoaded) {
    return (
      <Backbone
        backgroundColor={getGlobalStyle('--color-loaders-background')}
        borderRadius={null}
        foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
        height={height}
        speed={3}
        type='Generic'
        width={500}
      />
    )
  }

  return (
    <GoogleMap
      center={{
        lat: location.latMap,
        lng: location.lngMap
      }}
      mapContainerStyle={{
        width: '100%',
        height: `${height}px`
      }}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      }}
      zoom={15}
    >
      <Marker
        draggable
        icon={{
          url: './images/iconMap.png',
          anchor: new google.maps.Point(17, 46),
          scaledSize: new google.maps.Size(26, 32)
        }}
        onDrag={() => { errorAddress && coordsError('', 'message') }}
        onDragEnd={(mapCoords) => {
          pin(mapCoords.latLng)
        }}
        position={{
          lat: location.latMap,
          lng: location.lngMap
        }}
      />
    </GoogleMap>
  )
}