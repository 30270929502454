import { useSession } from '@smu-chile/pkg-unimarc-hooks'
import { CarouselCollections } from './Collection'
import { CarouselMyUsual } from './MyUsual'

export interface RenderProductsCarouselProps{
    content: object[];
    index: number;
    customTitles: object;
  }
export const RenderProductsCarousel = ({
  content,
  index,
  customTitles
}: RenderProductsCarouselProps) => {
  const refType: string = content?.['referenceType']
  const { isLoggedIn } = useSession()

  if (refType?.toLocaleUpperCase() === 'MYUSUALS' && isLoggedIn) {
    return (
      <CarouselMyUsual
        clusterData={content}
        key={index}
        titleSizes={customTitles}
      />
    )
  }
  if (refType?.toLocaleUpperCase() === 'COLLECTION'){
    return (
      <CarouselCollections
        clusterData={content}
        key={index}
        titleSizes={customTitles}
      />
    )
  }

  return null
}