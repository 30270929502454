import { useEffect } from 'react'
import { useRouter } from 'next/router'

import {
  generateBreadcrumbJoin,
  useContentful,
  usePagination
} from '@smu-chile/pkg-unimarc-hooks'
import { IProductsIntelligenceSearchResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsIntelligenceSearch'

import { changeUrlCategories, promotionsOfShelves } from '../../shared/helpers'
import { dataForBreadcrumbs } from 'shared/helpers'
import { ErrorPageSearch } from '../ProductSearchPage/ErrorPageSearch'
import { SearchBodyProduct, Main } from 'components/ProductSearchPage/SearchBodyProduct'

interface ProductBrandPros {
  page: number;
  search: string;
  intelliSearchData: IProductsIntelligenceSearchResponse;
}

export const ProductBrandPage = ({
  search,
  page,
  intelliSearchData
}: ProductBrandPros) => {
  const router = useRouter()
  const { breadcrumbData, title } = dataForBreadcrumbs({ router, search, brand: true })
  const dataBreadCrumb = changeUrlCategories(breadcrumbData)

  generateBreadcrumbJoin(dataBreadCrumb)

  // Get all promotions available in contenful
  const resultPromotions = useContentful({
    options: { content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE }
  })
  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })
  const shelves = []

  const products = [
    ...intelliSearchData?.data?.availableProducts || [],
    ...intelliSearchData?.data?.notAvailableProducts || []
  ]

  const totalQuantity = parseInt(intelliSearchData.data?.resources) || 0
  const isLoadingPage = false//resultIntelligenceSearch.isLoading || resultPromotions.isLoading

  const setProductsRange = (next: number) => {
    return next
  }

  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: setProductsRange
  })

  const dataOfBodyPageProducts = {
    breadcrumbData: dataBreadCrumb,
    title,
    totalQuantity,
    propsNavigation,
    isLoadingPage,
    products,
    dataPromotions,
    shelves
  }
  useEffect(() => {
    setProductsRange(page)
  }, [])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  },[dataBreadCrumb])

  if (!isLoadingPage && totalQuantity == 0) {
    return (
      <Main>
        <ErrorPageSearch message='resultados para la marca' search={search} />
      </Main>
    )
  }

  return (
    <SearchBodyProduct {...dataOfBodyPageProducts} />
  )
}