import { useEffect } from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Tooltip,
  Text,
  TooltipProps
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  datalayerSimpleEvent,
  ItemFields,
  sleep
} from '@smu-chile/pkg-unimarc-hooks'
import styles from './ToastWrapper.module.css'
import { dataLayerMembership } from 'shared/helpers'

export type IToastWrapperProps = TooltipProps & {
  errorCode?: string
  eventContext?: string
  membershipStatus?: string
  message?: string
  planSelected?: ItemFields
  success?: boolean
  onClose?: () => void
}

export const ToastWrapper = ({
  errorCode,
  eventContext,
  membershipStatus,
  message,
  planSelected,
  success,
  onClose,
  ...props
}: IToastWrapperProps) => {
  useEffect(() => {
    if (eventContext && !success) {
      const dataLayer = dataLayerMembership({
        status: membershipStatus,
        eventContext,
        planSelected,
        type: 'toast'
      })
      datalayerSimpleEvent({ ...dataLayer })
    }
    if (onClose) {
      const hideOnTimeout = async () => {
        await sleep(4000)
        onClose()
      }
      hideOnTimeout()
    }
  }, [])

  return (
    <Container
      customClassName={success && styles['toast-container']}
      justifyContent='center'
    >
      <Tooltip
        alignAllItems='center'
        background={
          success
            ? getGlobalStyle('--color-feedback-success-bg')
            : getGlobalStyle('--color-feedback-error-bg')
        }
        borderRadius={getGlobalStyle('--border-radius-2sm')}
        boxShadow={getGlobalStyle('--box-shadow-3md')}
        closeColor={getGlobalStyle('--color-icons-black')}
        closeIcon={false}
        containerProps={{
          maxWidth: success ? '373px' : 'none',
          position: 'sticky',
          ...props.containerProps
        }}
        customJustifyContent='center'
        display='flex'
        height='56px'
        id={getBemId('tooltipMessage', 'container')}
        maxWidth={success ? '343px' : '373px'}
        onClick={onClose}
        padding='12px 12px 12px 8px'
        textComponent={
          <Container>
            <Row
              alignItems='center'
              gap='8px'
            >
              <Column maxWidth='max-content'>
                {success ? (
                  <Icon
                    color={getGlobalStyle('--color-feedback-success-light')}
                    customSize={24}
                    name='CheckCircle'
                  />
                ) : (
                  <Container customClassName={styles['icon-transform']}>
                    <Icon
                      color={getGlobalStyle('--color-feedback-error')}
                      customSize={24}
                      name='Warning'
                    />
                  </Container>
                )}
              </Column>
              <Column maxWidth={success ? '267px' : '298px'}>
                <Text
                  color='black'
                  display='initial'
                  fontSize='md'
                  fontWeight='medium'
                  id={getBemId('tooltipMessage', 'text')}
                  lineHeight='15px'
                  type='span'
                >
                  {message}
                  {errorCode && (
                    <Text
                      color='gray'
                      display='initial'
                      fontSize='2xs'
                      type='span'
                    >
                      &nbsp; ({errorCode})
                    </Text>
                  )}
                </Text>
              </Column>
              <Column
                clickable='pointer'
                maxWidth='max-content'
              >
                <Icon
                  clickable='pointer'
                  color='black'
                  customSize={16}
                  name='CloseThin'
                />
              </Column>
            </Row>
          </Container>
        }
        top='0'
        type='bottom'
        width={success ? '343px' : '373px'}
        {...props}
      />
    </Container>
  )
}
