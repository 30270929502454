import { simulateCart } from '@smu-chile/pkg-unimarc-hooks'
import { ISimulateCartProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostSimulateCart'

export const directionCoverage = async ({
  country,
  geoCoordinates,
  items,
  addressType
}): Promise<ISimulateCartProps[]> => {
  const data = await simulateCart({
    country,
    geoCoordinates,
    items,
    addressType
  })
  const slaValue = data?.slas?.find(logisticsInfo => { return logisticsInfo.availableDeliveryWindows.length > 0 && logisticsInfo.availableDeliveryWindows[0] })
  if (slaValue) {
    return [data, { isCoverage: slaValue ? true : false }]
  }
  return [data, { isCoverage: false }]
}