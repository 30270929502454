import {
  useMemo,
  useState
} from 'react'
import { useRouter } from 'next/router'
import {
  DeliveryMediumModalResult,
  ForgotPasswordModalProps,
  ForgotPasswordStep,
  ResetPasswordModalResult,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  FlowId,
  postForgotPasswordFinish,
  postForgotPasswordStart,
  postTryAuthorize
} from '@smu-chile/pkg-unimarc-hooks'
import HttpError from '@smu-chile/pkg-unimarc-hooks/shared/errors/HttpError'
import { DeliveryMedium } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IForgotPassword'
import { UnknownErrorTooltip } from './Tooltips/UnknownErrorTooltip'
import { UserNotFoundTooltip } from './Tooltips/UserNotFoundTooltip'
import { CodeNotFoundTooltip } from './Tooltips/CodeNotFoundTooltip'
import { CodeBlockedTooltip } from './Tooltips/CodeBlockedTooltip'
import { CodeResendTooltip } from './Tooltips/CodeResendTooltip'
import { CodeExpiredTooltip } from './Tooltips/CodeExpiredTooltip'
import { ResetPasswordConfirmModal } from './Modals/ResetPasswordConfirmModal'
import { DeliveryMediumModalBrowse } from './Modals/DeliveryMediumModalBrowse'
import { ConfirmCodeModalBrowse } from './Modals/ConfirmCodeModalBrowse'
import { ResetPasswordModalBrowse } from './Modals/ResetPasswordModalBrowse'

const DEFAULT_RETRY_AFTER = 60*5 // (5min)

export interface ForgotPasswordModalBrowseProps extends ForgotPasswordModalProps {
  imageSrc: string
  onBack?: () => void
  onChangeStep?: (step: ForgotPasswordStep) => void
}

export const ForgotPasswordModalBrowse = ({
  imageSrc,
  onBack,
  onChangeStep,
  onClose,
  ...props
}: ForgotPasswordModalBrowseProps) => {
  const router = useRouter()
  const [codeError, setCodeError] = useState('')
  const [loading, setLoading] = useState(false)

  const [forgotPasswordData, setForgotPasswordData] = useState({
    code: '',
    deliveryMedium: '',
    deliveryTarget: '',
    retryAfter: 0,
    rut: ''
  })

  const deliveryMedium = useMemo(() => {
    if (forgotPasswordData.deliveryMedium === 'SMS') {
      return 'teléfono'
    }

    return 'correo'
  }, [forgotPasswordData.deliveryMedium])

  const currentStep = useMemo(() => {
    if (!router.isReady) return ForgotPasswordStep.DeliveryMedium
    const step = router.query.step as ForgotPasswordStep
    if (Object.values(ForgotPasswordStep).includes(step)) return step
    return ForgotPasswordStep.DeliveryMedium
  }, [router.query])

  const handleChangeStep = (step: ForgotPasswordStep) => {
    router.push({
      href: router.asPath,
      query: {
        ...router.query,
        step: step
      }
    }, undefined, {
      shallow: true
    })
    onChangeStep?.(step)
  }

  const handleBackResetPassword = () => {
    handleChangeStep(ForgotPasswordStep.Code)
  }

  const handleConfirmCodeModalBack = () => {
    handleChangeStep(ForgotPasswordStep.DeliveryMedium)
  }

  const handleConfirmCodeModalRetry = async () => {
    try {
      setLoading(true)

      await postForgotPasswordStart(
        forgotPasswordData.deliveryMedium as DeliveryMedium,
        forgotPasswordData.rut
      )

      setCodeError('code-resend')
    } catch (error) {
      if (error.status === 403) {
        setCodeError('user-not-found')
      } else {
        setCodeError('unknown-error')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleConfirmCodeModalSubmit = async (code: string) => {
    setForgotPasswordData({
      ...forgotPasswordData,
      code
    })

    handleChangeStep(ForgotPasswordStep.ResetPassword)
  }

  const handleDeliveryMediumModalSubmit = async ({ deliveryMedium, rut }: DeliveryMediumModalResult) => {
    try {
      setLoading(true)

      const resultTryAuthorize = await postTryAuthorize({ rut })

      if (
        resultTryAuthorize.data.flowId === FlowId.Register ||
        resultTryAuthorize.data.flowId === FlowId.RegisterWithFiles
      ) {
        throw new HttpError('', 403, {})
      }

      const { data } = await postForgotPasswordStart(deliveryMedium as DeliveryMedium, rut)

      setForgotPasswordData({
        ...forgotPasswordData,
        deliveryMedium,
        deliveryTarget: data?.destination ?? '',
        rut
      })

      handleChangeStep(ForgotPasswordStep.Code)
    } catch (error) {
      if (error.status === 403) {
        setCodeError('user-not-found')
      } else {
        setCodeError('unknown-error')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleForgotPasswordBack = () => {
    onBack?.()
  }

  const handleForgotPasswordClose = () => {
    onClose?.()
  }

  const handleResetPasswordConfirmClose = () => {
    handleForgotPasswordClose()

    setTimeout(async () => {
      await router.push({ query: {} }, undefined)
      router.reload()
    }, 250)
  }

  const handleResetPasswordSubmit = async ({ proposalPassword }: ResetPasswordModalResult) => {
    try {
      setLoading(true)

      const {
        data,
        status,
        statusText
      } = await postForgotPasswordFinish(
        proposalPassword,
        forgotPasswordData.code,
        forgotPasswordData.rut
      )

      if (status !== 200) throw new HttpError(statusText, status, data)

      handleChangeStep(ForgotPasswordStep.ConfirmDeliveryMedium)
    } catch (error) {
      switch (error.status) {
        case 400:
        case 404:
          setCodeError('code-not-found')
          handleBackResetPassword()
          break
        case 410:
          setCodeError('code-expired')
          handleBackResetPassword()
          break
        case 429:
          setCodeError('code-blocked')
          setForgotPasswordData({
            ...forgotPasswordData,
            retryAfter: error.data?.retryAfter ?? DEFAULT_RETRY_AFTER
          })
          handleBackResetPassword()
          break
        default:
          setCodeError('unknown-error')
          break
      }
    } finally {
      setLoading(false)
    }
  }

  const handleTooltipClose = () => {
    setCodeError('')
  }

  return (
    <>
      {currentStep === ForgotPasswordStep.DeliveryMedium && props.isOpen && (
        <DeliveryMediumModalBrowse
          image={imageSrc}
          loading={loading}
          onBack={handleForgotPasswordBack}
          onClose={handleForgotPasswordBack}
          onOutsideClose={handleForgotPasswordClose}
          onSubmit={handleDeliveryMediumModalSubmit}
        >
          {codeError === 'user-not-found' && (
            <UserNotFoundTooltip onClose={handleTooltipClose} />
          )}
          {codeError === 'unknown-error' && (
            <UnknownErrorTooltip onClose={handleTooltipClose} />
          )}
        </DeliveryMediumModalBrowse>
      )}

      {currentStep === ForgotPasswordStep.Code && props.isOpen && (
        <ConfirmCodeModalBrowse
          onClose={handleForgotPasswordBack}
          onOutsideClose={handleForgotPasswordClose}
          onPrependIconClick={handleConfirmCodeModalBack}
          onRetry={handleConfirmCodeModalRetry}
          onSubmit={handleConfirmCodeModalSubmit}
          prependSlot={
            <>
              {codeError === 'code-blocked' && (
                <CodeBlockedTooltip onClose={handleTooltipClose} retryAfter={forgotPasswordData.retryAfter}/>
              )}
              {codeError === 'code-expired' && (
                <CodeExpiredTooltip onClose={handleTooltipClose} />
              )}
              {codeError === 'code-not-found' && (
                <CodeNotFoundTooltip onClose={handleTooltipClose} />
              )}
              {codeError === 'code-resend' && (
                <CodeResendTooltip onClose={handleTooltipClose} />
              )}
              {codeError === 'unknown-error' && (
                <UnknownErrorTooltip onClose={handleTooltipClose} />
              )}
            </>
          }
          target={forgotPasswordData.deliveryTarget}
          text={
            <>
              {'Enviamos un código a tu '}
              <Text
                display='contents'
                fontSize='md'
                fontWeight='semibold'
                type='span'
              >
                {deliveryMedium}
              </Text>
              {' para restablecer la contraseña.'}
            </>
          }
        />
      )}

      {currentStep === ForgotPasswordStep.ResetPassword && props.isOpen && (
        <ResetPasswordModalBrowse
          loading={loading}
          onBack={handleBackResetPassword}
          onClose={handleForgotPasswordBack}
          onOutsideClose={handleForgotPasswordClose}
          onSubmit={handleResetPasswordSubmit}
        >
          {codeError === 'unknown-error' && (
            <UnknownErrorTooltip onClose={handleTooltipClose} />
          )}
        </ResetPasswordModalBrowse>
      )}

      {currentStep === ForgotPasswordStep.ConfirmDeliveryMedium && props.isOpen && (
        <ResetPasswordConfirmModal
          onClose={handleResetPasswordConfirmClose}
          onSubmit={handleResetPasswordConfirmClose}
        />
      )}
    </>
  )
}
