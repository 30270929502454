import { ShelvesDataAlvi } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICart'

/**
 * Format product quantity display to safety value with unit
 * measurement
 * @param {ShelvesDataAlvi} product - product data
 * @returns
 */
export function formatQuantityDisplay(product: ShelvesDataAlvi) {
  if (
    product?.showQuantity &&
    product?.showQuantity.toString().trim().split(' ').length === 1
  ) {
    return `${product?.showQuantity.toString().trim()} un`
  }

  return product?.showQuantity
}
