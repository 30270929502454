import { Column, ConfirmModal, Container, Icon, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import { BigScreen, SmallScreen, getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useUserAlvi } from '@smu-chile/pkg-unimarc-hooks'

export type WelcomeMerchantModalProps = {
  isLoading?: boolean
  onClose?: VoidFunction
  text?: string
}

export const WelcomeMerchantModal = ({ onClose }: WelcomeMerchantModalProps) => {
  const user = useUserAlvi()

  if (!user.data.email) {
    return null
  }

  return (
    <>
      <BigScreen>
        <ConfirmModal
          buttonBorderRadius={getGlobalStyle('--border-radius-lg')}
          buttonContainerProps={{
            maxWidth: 'initial'
          }}
          buttonLabel='Ir a comprar'
          buttonLabelWeight={getGlobalStyle('--font-weight-medium')}
          footerContainerProps={{
            borderRadius: getGlobalStyle('--border-radius-2lg')
          }}
          headerDividerColor={getGlobalStyle('--color-neutral-gray-divider')}
          headerDividerSize='3xs'
          onClose={onClose}
          onSubmit={onClose}
          site='alvi'
          title='Registro realizado'
          titleSize='xl'
          titleWeight='semibold'
          width='440px'
        >
          <Spacer.Horizontal customSize={72} />

          <Container justifyContent='center'>
            <Icon
              color={getGlobalStyle('--color-alvi-icons-success')}
              customSize={32}
              name='CheckCircle'
            />
          </Container>

          <Spacer.Horizontal size={24} />

          <Column alignItems='center' maxWidth='350px'>
            <Text
              fontSize='xl'
              fontWeight='semibold'
              textAlign='center'
            >
              ¡Gracias por completar tu registro!
            </Text>

            <Spacer.Horizontal size={32} />

            <Text display='block' textAlign='center'>
              Enviaremos un correo a <strong>{user.data.email} dentro de 48 horas validando tu cuenta</strong> para que puedas disfrutar de más
              descuentos y beneficios.
            </Text>
          </Column>

          <Spacer.Horizontal customSize={90} />
        </ConfirmModal>
      </BigScreen>

      <SmallScreen>
        <ConfirmModal
          buttonBorderRadius={getGlobalStyle('--border-radius-lg')}
          buttonLabel='Ir a comprar'
          buttonLabelWeight={getGlobalStyle('--font-weight-medium')}
          footerContainerProps={{
            borderRadius: getGlobalStyle('--border-radius-2lg'),
            padding: '20px 20px 40px'
          }}
          fullScreen
          headerDividerColor={getGlobalStyle('--color-neutral-gray-divider')}
          headerDividerSize='3xs'
          onClose={onClose}
          onSubmit={onClose}
          site='alvi'
          title={<strong>Registro realizado</strong>}
          titleWeight='semibold'
        >
          <Spacer.Horizontal customSize={42} />

          <Container justifyContent='center'>
            <Icon
              color={getGlobalStyle('--color-alvi-icons-success')}
              customSize={28}
              name='CheckCircle'
            />
          </Container>

          <Spacer.Horizontal size={16} />

          <Column alignItems='center' maxWidth='340px'>
            <Text
              fontSize='xl'
              fontWeight='semibold'
              textAlign='center'
            >
              <strong>¡Gracias por completar tu registro!</strong>
            </Text>

            <Spacer.Horizontal size={32} />

            <Text display='block' textAlign='center'>
              Enviaremos un correo a <strong>{user.data.email} dentro de 48 horas validando tu cuenta</strong>
              para que puedas disfrutar de más
              descuentos y beneficios.
            </Text>
          </Column>

          <Spacer.Horizontal customSize={36} />
        </ConfirmModal>
      </SmallScreen>
    </>
  )
}
