import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { cleanMembershipQueryParam } from 'shared/helpers'
import { ModalMembershipTypeContent } from './components/ModalMembershipTypeContent'

interface ModalMembershipTypeWrapperProps {
  membershipLevel?: string
  handleToggle: () => void
}

export const MODAL_MEMBERSHIP_TYPE_WRAPPER_ID = {
  MOBILE: 'modalMembershipTypeWrapperMobile',
  DESKTOP: 'modalMembershipTypeWrapperDesktop'
}

export const MODAL_MEMBERSHIP_TYPE_WRAPPER_CONFIGS = {
  CTA_TEXT: 'Entendido'
}
export const ModalMembershipTypeWrapper = ({
  membershipLevel = 'clubalvi',
  handleToggle
}: ModalMembershipTypeWrapperProps): React.ReactElement => {
  const router = useRouter()

  const handleClose = () => {
    cleanMembershipQueryParam(router, handleToggle)
  }

  const handleRedirect = async () => {
    router.push('/club-alvi', undefined, { shallow: false })
    await sleep(1500)
    handleToggle()
  }
  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={MODAL_MEMBERSHIP_TYPE_WRAPPER_ID.DESKTOP}
          bodyChildrenProps={{
            width: '100%',
            maxHeight: 'initial',
            padding: '0 49px'
          }}
          borderEdge='none'
          footerChildrenProps={{
            buttonDefaultProps: {
              maxWidth: '400px',
              onClick: handleRedirect,
              fontWeight: getGlobalStyle('--font-weight-medium'),
              site: 'alvi',
              label: MODAL_MEMBERSHIP_TYPE_WRAPPER_CONFIGS.CTA_TEXT
            },
            boxShadow: 'none',
            default: true
          }}
          hasfooter={true}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            maxWidth: '800px',
            isWindowBlocked: true,
            maxHeight: '440px',
            minHeight: '450px',
            isOpen: true,
            toggle: handleClose,
            toggleOutside: handleClose
          }}
          styleProps={{
            justifyContent: undefined,
            display: 'grid',
            customGridTemplateColumns: '1fr',
            gridTemplateRows: 'auto 1fr auto',
            customHeightModalContent: '100%'
          }}
        >
          <ModalMembershipTypeContent membershipLevel={membershipLevel} />
        </DesktopModalWrapper>
      </BigScreen>

      <SmallScreen>
        <MobileModalWrapper
          blockId={MODAL_MEMBERSHIP_TYPE_WRAPPER_ID.MOBILE}
          body={
            <ModalMembershipTypeContent membershipLevel={membershipLevel} />
          }
          bodyContainerProps={{
            alignItems: 'center'
          }}
          buttonConfigsProps={{
            site: 'alvi',
            onClick: handleRedirect,
            fontWeight: getGlobalStyle('--font-weight-medium'),
            label: MODAL_MEMBERSHIP_TYPE_WRAPPER_CONFIGS.CTA_TEXT,
            isLoading: false
          }}
          dragProps={{
            draggButton: false
          }}
          hasFooter={true}
          hasShadow={false}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={16}
          isEnableButton
          isOpen={true}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleClose,
            toggleOutside: handleClose
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
