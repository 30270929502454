import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Format product promotion to shelf promotion chip
 * @param {any} product - product data
 * @returns
 */
export function formatPromotion(product: unknown) {
  if (
    product?.['promotionalTag'] &&
    isValidArrayWithData(Object.keys(product?.['promotionalTag'])) &&
    product?.['promotionalTag'].constructor === Object
  ) {
    const promotionalTag = product?.['promotionalTag']

    return {
      backgroundColor: promotionalTag?.color ?? '',
      color: promotionalTag?.textColor ?? '',
      label: promotionalTag?.campaignName ?? ''
    }
  }

  return null
}
