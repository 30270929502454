import { IconNames } from '@smu-chile/pkg-unimarc-components'

export const couponProps = ({
  level
}: {
  level: string
}) => {
  const levelButtons = {
    vip: {
      level: 'Socio Vip',
      levelIcon: 'Diamond' as IconNames
    },
    oro: {
      level: 'Socio Oro',
      levelIcon: 'Crown' as IconNames
    },
    plata: {
      level: 'Socio Plata',
      levelIcon: 'Star' as IconNames
    },
    club: {
      level: 'Socio Club',
      levelIcon: 'Star' as IconNames
    },
    guest: {
      outlined: true
    }
  }

  if (level) {
    return levelButtons[level] ?? levelButtons.club
  }

  return levelButtons.guest
}