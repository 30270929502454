import classNames from 'classnames'
import { Modal, Column, Picture, Container, Text, Divider } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { BenefitsModalProps, BenefitsModalTestIds } from 'components/BenefitsModal'
import styles from './BenefitsModalMobile.module.css'

export type BenefitsModalMobileProps = BenefitsModalProps

export const BenefitsModalMobile = ({
  description = 'Estos son los beneficios que tienes en tu nivel',
  imageSrc,
  isOpen,
  onClose,
  title = 'Mis beneficios'
}: BenefitsModalMobileProps) => {
  return (
    <Modal
      bussinessProps={{
        site: 'alvi'
      }}
      dragProps={{
        draggButton: false
      }}
      iconsProps={{
        sizeIconClose: 20,
        customCloseIcon: 'CloseThin',
        iconPadding: '24px'
      }}
      modalConfigsProps={{
        customContainerClassName: classNames({
          [styles['benefitsModal__main']]: true
        }),
        fullScreen: true,
        containerId: BenefitsModalTestIds.modal,
        isOpen: isOpen,
        minHeightFullScreen: 'calc(100vh - 100px)',
        toggle: onClose,
        toggleOutside: onClose
      }}
      styleProps={{
        padding: '0'
      }}
    >
      <Column>
        <Column>
          <Container justifyContent='center' padding='24px'>
            <Text
              fontSize='lg'
              fontWeight='semibold'
              id={BenefitsModalTestIds.title}
            >
              {title}
            </Text>
          </Container>

          <Divider
            borderHeight='xs'
            color={getGlobalStyle('--color-neutral-gray-light')}
            verticalMargin={0}
          />
        </Column>

        <Column
          alignItems='center'
          maxHeight='100%'
        >
          <Container
            justifyContent='center'
            padding='16px 16px 4px'
          >
            <Text
              fontSize='md'
              fontWeight='regular'
              id={BenefitsModalTestIds.description}
              textAlign='center'
            >
              {description}
            </Text>
          </Container>

          <Picture
            height='auto'
            id={BenefitsModalTestIds.picture}
            lineHeight={0}
            objectFit='cover'
            src={imageSrc}
            width='100%'
          />
        </Column>
      </Column>
    </Modal>
  )
}
