import { useState } from 'react'
import {
  Banners,
  Column,
  DataBody,
  DropdownWithRound,
  Row,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  addToHomeData,
  clickedPromos,
  getContentfulImageCategories,
  isValidArrayWithData,
  useCategories,
  useContentful,
  UseQueryOptions
} from '@smu-chile/pkg-unimarc-hooks'
import {
  PropsHome,
  CategoriesDataImageProps,
  SendPromoDataProps,
  SendPromoDataImageBanner
} from 'shared/interfaces/Home'
import { searchImageBannerByLevel } from 'shared/helpers/filterImageBannersWeb'

const clickBanner = (promosData) => {
  clickedPromos(promosData)
}

const validateLayout = (
  data,
  isMobile,
  site,
  userId,
  userLevel,
  orderForm
) => {
  const contentObject = isMobile ? 'imageMobile' : 'imageDesktop'
  const imageBanners = data?.['imageBanner']

  if (data['layout'] == 'uno solo' && isValidArrayWithData(imageBanners)) {
    const enabledBanners = searchImageBannerByLevel(imageBanners, userLevel)
    const [imageBanner] = isValidArrayWithData(enabledBanners) ? enabledBanners : imageBanners
    const banner: string = imageBanner?.[contentObject]?.['fields']?.['file']?.['url']
    return {
      bannerOnlyOne: {
        banner,
        isMobile: isMobile,
        onRedirect: (event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation()
          clickBanner({ site, userId, orderForm })
        },
        url: imageBanner?.['reference']
      }
    }
  }
  if (data['layout'] == 'grid' ||
    data['layout'] == 'carousel' ||
    data['layout'] == 'occasion of consumption'
  ) {

    const nameType = (
      data['layout'] === 'grid' || data['layout'] === 'occasion of consumption' ?
        'bannersGrid' : 'bannersCarousel'
    )

    const bannerType: Array<{ img: string, url: string }> = data['imageBanner']?.map((bannerInfo, index) => {
      return {
        creativeName: bannerInfo['creativeName'],
        creativeSlot: bannerInfo['creativeSlot'],
        img: bannerInfo[contentObject]['fields']['file']['url'],
        locationId: index + 1,
        promotionId: bannerInfo['promotionId'],
        promotionName: bannerInfo['promotionName'],
        url: bannerInfo['reference'],
        onRedirect: (event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation()
          clickBanner({ site, userId, orderForm })
        }
      }
    })
    return { [nameType]: bannerType }
  }
  return {}
}

const sendPromoData = ({
  cfIndex,
  data,
  ref
}: SendPromoDataProps) => {
  let promoData: SendPromoDataImageBanner = { ...data, locationId: `${cfIndex} - ${data?.['locationId']}` }

  if (data['layout'] == 'uno solo') {
    const bannerImage = data['imageBanner'][0]
    promoData = {
      creativeName: bannerImage['creativeName'],
      creativeSlot: bannerImage['creativeSlot'],
      locationId: cfIndex,
      promotionId: bannerImage['promotionId'],
      promotionName: bannerImage['promotionName']
    }
  }

  addToHomeData({ ref, promoData })
}

const getCategoriesData = ({ categoriesApp, resultAisles }): DataBody[] => {
  const categoriesDataImage: CategoriesDataImageProps[] = getContentfulImageCategories(categoriesApp?.data)
  return resultAisles?.data?.data?.map?.(item => {
    let url = item.url
    if (/\.com\.br(.+)/.test(url)) url = `/category` + /\.com\.br(.+)/.exec(url)[1]
    return {
      href: url,
      image: Array.isArray(categoriesDataImage) && categoriesDataImage.find(({ idCategory }: { idCategory: string }) => {
        return idCategory == item.id
      })?.categoryImage?.file?.url,
      name: item.name
    }
  }) || []
}

const getDropdownData = ({ data, isMobile }: { isMobile: boolean, data: Product }): DataBody[] => {
  return data?.['imageBanner']?.map(item => {
    const typeOfImage = isMobile ? 'imageDesktop' : 'imageMobile'
    return {
      href: item?.reference,
      image: item?.[typeOfImage]?.fields?.file?.url,
      name: item?.creativeName
    }
  })
}

export const BannersWeb = ({
  cfIndex,
  data,
  isMobile,
  orderForm,
  site,
  titleSizes,
  userId,
  userLevel
}: PropsHome) => {
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const isCategories: boolean = data['layout'] == 'DropDownWithRound' && data['idReference'] == 'categories'
  const reactQueryCategories: UseQueryOptions = { enabled: isCategories }
  const resultAisles = useCategories({ level: 2, reactQuery: reactQueryCategories })
  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE
    },
    reactQuery: reactQueryCategories
  })
  let dataDropdownRound: DataBody[] | [] = []
  if (data['layout'] == 'DropDownWithRound') {
    if (isCategories) dataDropdownRound = getCategoriesData({ resultAisles, categoriesApp })
    if (!isCategories) dataDropdownRound = getDropdownData({ data, isMobile })
  }
  const isAllowDropdownAction = dataDropdownRound.length > 4
  const props = validateLayout(
    data,
    isMobile,
    site,
    userId,
    userLevel,
    orderForm
  )
  const backgroundColor: string = data['layout'] === 'occasion of consumption' ? data['color'] : 'none'
  const paddingRow: string = data['layout'] === 'occasion of consumption' ? '0' : '12px'
  const paddingTitleMobile: string = isMobile ? '15px 28px' : '15px 20px'
  const paddinTitle = (
    data['layout'] === 'occasion of consumption' ?
      paddingTitleMobile : '19px 20px'
  )

  if (data['layout'] == 'DropDownWithRound') {
    return (
      <Column
        alignItems='center'
        backgroundColor={backgroundColor}
        maxWidth={getGlobalStyle('--width-max-desktop')}
      >
        <DropdownWithRound
          dataBody={dataDropdownRound}
          handleChangeDropDown={() => {
            setIsOpenDropDown(!isOpenDropDown)
          }}
          isOpen={isOpenDropDown}
          showMore={isAllowDropdownAction}
          title={data['title']}
        />
      </Column>
    )
  }

  return (
    <Column
      alignItems='center'
      backgroundColor={backgroundColor}
      maxWidth={getGlobalStyle('--width-max-desktop')}
    >
      {(data['title'] || data['subtitle']) &&
        <Column padding={paddinTitle}>
          <Row>
            <Title
              fontWeight='semibold'
              headingLevel='h2'
              {...titleSizes['h2']}
              text={data['title']}
            />
          </Row>
          <Row>
            <Title
              fontWeight='regular'
              headingLevel='h3'
              {...titleSizes['h3']}
              text={data['subtitle']}
            />
          </Row>
        </Column>
      }
      <Row padding={paddingRow}>
        <Banners
          catchPromoData={(promoData?: { ref?: object, banner?: object }) => {
            sendPromoData({
              cfIndex: `${cfIndex}`,
              data: promoData.banner || data,
              ref: promoData?.ref || promoData
            })
          }}
          layout={data['layout']}
          minHeight={data['height']}
          {...props}
        />
      </Row>
    </Column>
  )
}