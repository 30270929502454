import { getContentfulData } from '@smu-chile/pkg-unimarc-hooks'
import { CONTENTFUL_LOGIN_BANNER } from 'shared/utils/constanst'

export async function getContentfulLoginBanner() {
  const contentful = await getContentfulData({
    options: {
      'sys.id': CONTENTFUL_LOGIN_BANNER[0]
    },
    reactQuery: {
      queryKey: 'loginAlviBanner'
    },
    type: 'assets'
  })

  const [loginBanner] = contentful?.items ?? []
  return loginBanner ?? null
}
