import { WelcomeMerchantModal } from 'components/WelcomeMerchantModal'
import { useRouter } from 'next/router'

export const AuthBrowse = () => {
  const router = useRouter()

  const showWelcomeMerchantModal = router.query.welcome === 'merchant'

  const handleWelcomeMerchantModalClose = () => {
    const { pathname, query } = router
    delete router.query.welcome
    router.replace({ pathname, query }, undefined, { shallow: true })
  }

  return (
    <>
      {showWelcomeMerchantModal && (
        <WelcomeMerchantModal onClose={handleWelcomeMerchantModalClose} />
      )}
    </>
  )
}
