import { ChangeEvent } from 'react'
import {
  BackboneShelvesAlvi,
  Column,
  FontSizeLink,
  Link,
  QuantityButtonStylesProps,
  Row,
  ShelfCarouselAlvi,
  ShelfAlviProps,
  Sizes,
  Spacer,
  Title,
  TitleProps
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ChildrenBaseContainerProps } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import {
  addToHomeData,
  productClickTag,
  useAlviSession,
  useUser,
  visiblePromos
} from '@smu-chile/pkg-unimarc-hooks'

export interface CarouselShelfData {
  itemsToShow: number
  itemsToSlide: number
  positionContainer: ChildrenBaseContainerProps
  productsMemo: ShelfAlviProps[]
  spacerBelow: Sizes
  handleAdd: (props?: { itemId: string }) => void
  handleChange: (
    e?: ChangeEvent<HTMLInputElement>,
    props?: { itemId: string }
  ) => void
  handleOnClick: (props?: { itemId: string }) => void
  handleRemove: (props?: { itemId: string }) => void
}

export interface ContentCarouselProps {
  carouselHeaderPadding: string
  carouselShelData: CarouselShelfData
  clusterData: object[]
  isLoading: boolean
  isMobile: boolean
  linkFontSize: FontSizeLink
  titleSizes: TitleProps
}
export const ContentCarousel = ({
  carouselHeaderPadding,
  carouselShelData,
  clusterData,
  isLoading,
  isMobile,
  linkFontSize,
  titleSizes
}: ContentCarouselProps) => {
  const user = useUser()
  const userId = user?.userId
  const { isLoggedIn } = useAlviSession()
  const site = 'Alvi'

  const validationStatus = user?.validationStatus ?? 'logout'
  const handleHomeShelf = ({ ref, shelf }) => {
    addToHomeData({ ref, promoData: shelf, isShelf: true })
  }
  const customQuantityButton: QuantityButtonStylesProps = isMobile
    ? {
      fontSize: 'md',
      maxHeight: '30px',
      maxWidth: '100%',
      minWidth: '140px',
      sizeCircle: 22
    }
    : {
      fontSize: 'md',
      maxHeight: '35px',
      maxWidth: '100%',
      minWidth: '220px',
      sizeCircle: 25
    }

  return (
    <Row justifyContent='center' maxWidth='1120px'>
      {isLoading || user === undefined ? (
        <BackboneShelvesAlvi
          columns={5}
          rows={1}
          version={2}
        />
      ) : (
        <Column>
          {(clusterData?.['title'] || clusterData?.['subtitle']) && (
            <Row justifyContent='between' padding={carouselHeaderPadding}>
              <Column customWidth='75'>
                <Row>
                  <Title
                    fontWeight='semibold'
                    {...titleSizes['h2']}
                    headingLevel='h2'
                    text={clusterData['title']}
                  />
                </Row>
                <Row>
                  <Title
                    fontWeight='regular'
                    headingLevel='h3'
                    {...titleSizes['h3']}
                    text={clusterData['subtitle']}
                  />
                </Row>
              </Column>
              <Column alignItems='end' customWidth='25'>
                {clusterData?.['url'] && (
                  <Link
                    color={getGlobalStyle('--color-alvi-icons-gray')}
                    fontSize={linkFontSize}
                    href={clusterData?.['url']}
                  >
                    Ver todos
                  </Link>
                )}
              </Column>
            </Row>
          )}
          <Row padding={getGlobalStyle('--border-radius-2xs')}>
            <ShelfCarouselAlvi
              carouselProps={{
                infinite: true,
                autoPlay: false,
                shouldResetAutoplay: false,
                afterChange: () => {
                  visiblePromos({ site: site, userId: userId })
                }
              }}
              catchShelfRef={handleHomeShelf}
              containerProps={carouselShelData?.positionContainer}
              handleOnClick={carouselShelData?.handleOnClick}
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
              items={carouselShelData?.productsMemo}
              itemsToShow={carouselShelData?.itemsToShow}
              onClickProduct={(product) => {
                productClickTag({
                  ...product,
                  listIdVal: `home_${clusterData?.['title'].replace(
                    / /g,
                    '_'
                  )}`,
                  listNameVal: `Home ${clusterData?.['title']}`
                })
              }}
              quantityButtonProps={{
                handleAdd: carouselShelData?.handleAdd,
                handleRemove: carouselShelData?.handleRemove,
                handleChange: carouselShelData?.handleChange
              }}
              quantityButtonStyles={customQuantityButton}
              showArrows={!isMobile}
              slidesToSlide={carouselShelData?.itemsToSlide}
              validationStatus={validationStatus}
            />
          </Row>
          <Spacer.Horizontal size={carouselShelData?.spacerBelow} />
        </Column>
      )}
    </Row>
  )
}
