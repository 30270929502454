import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const RegisterPasswordValidationColorsMap = {
  0: getGlobalStyle('--color-neutral-gray-dark'),
  1: getGlobalStyle('--color-feedback-success-light')
}

export const getValidationColor = (value = false) => {
  return RegisterPasswordValidationColorsMap[Number(value)]
}
