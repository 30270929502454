import {
  Breadcrumbs,
  Row,
  Title,
  Text,
  Spacer,
  Column,
  Container,
  BackboneShelvesAlvi
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import {
  Subcategories,
  CustomPagination,
  Shelves
} from 'components'
import { BigScreen, SmallScreen } from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import styles from './BodyPageProducts.module.css'
import { clickPlp } from 'shared/utils/googleAnalytics'

type BreadcrumbsData = {
  label: string;
  url?: string;
  oneClick?: (url: string) => void;
}

type SubcategoriesProp = {
  label: string;
  url: string;
}

type propsNavigation = {
  currentPage: number;
  pageQuantity: number;
  isVisableButtonLeft: boolean;
  isVisableButtonRight: boolean;
  isVisableButtons: boolean;
  items: (string | number)[];
  nextPage: () => void;
  prevPage: () => void;
  setPage: (value: number) => void;
}

export interface BodyPageProductsProps {
  breadcrumbData?: BreadcrumbsData[];
  title: string;
  totalQuantity: string | number;
  subCategories?: SubcategoriesProp[];
  propsNavigation: propsNavigation;
  isLoadingPage: boolean;
  products: AvailableProduct[] | Product[];
  dataPromotions: unknown[];
}

export const BodyPageProducts = ({
  breadcrumbData,
  title,
  totalQuantity,
  subCategories = [],
  propsNavigation,
  isLoadingPage = true,
  products,
  dataPromotions
}: BodyPageProductsProps) => {
  // use
  const { isMobile } = useMobile()
  // primitive
  const fontSizeQuantity = isMobile ? 'sm' : 'base'
  const justifyPaginationEnd = isMobile ? 'center' : 'end'

  const Pagination = () => {
    return (
      <CustomPagination {...{ ...propsNavigation, isLoadingPage }} backgroundCurrent={getGlobalStyle('--color-alvi-primary-blue3')} />
    )
  }

  const CustomHeader = () => {
    return (
      <>
        <Row>
          <Breadcrumbs
            afterClick={label => { clickPlp(label, 'breadcrumb') }}
            colorAlvi
            links={breadcrumbData}
          />
        </Row>
        <Spacer.Horizontal size={12} />
        <Row alignItems='baseline'>
          <Row justifyContent='start' width='auto'>
            <Title
              fontWeight='semibold'
              headingLevel='h4'
              size='small'
            >
              {title}
            </Title>
          </Row>
          <Spacer.Vertical size={8} />
          <Text
            color='gray'
            customClassName={styles.resultText}
            fontSize={fontSizeQuantity}
          >
            ({totalQuantity} resultados)
          </Text>
        </Row>
      </>
    )
  }
  return (
    <>
      <Spacer.Horizontal size={12} />
      <Container justifyContent='center'>
        <Column maxWidth='1160px'>
          {isMobile ?
            <Row
              alignItems='center'
              backgroundColor={getGlobalStyle('--color-alvi-neutral-gray-white')}
              isWrap
              padding='12px'
            >
              <CustomHeader />
            </Row>
            :
            <CustomHeader />
          }
          {subCategories?.length > 0 &&
            <Subcategories data={subCategories} />
          }
          {!isMobile && (
            <>
              <Spacer.Horizontal size={12} />
              <Row justifyContent='end'>
                <Pagination />
              </Row>
              <Spacer.Horizontal size={32} />
            </>
          )}
        </Column>
      </Container>

      <Container background='white' justifyContent='center'>
        <Row maxWidth='1160px'>
          {isLoadingPage ?
            <BackboneShelvesAlvi
              columns={5}
              rows={3}
              version={2}
            />
            :
            (<>
              <BigScreen>
                <Shelves
                  dataPromotions={dataPromotions}
                  isMobile={isMobile}
                  products={products}
                />
              </BigScreen>
              <SmallScreen>
                <Shelves
                  dataPromotions={dataPromotions}
                  isMobile={true}
                  padding='0 15px'
                  products={products}
                  shelfWidth='calc(50% - 12px)'
                />
              </SmallScreen>
            </>
            )
          }
        </Row>
      </Container>

      <Spacer.Horizontal size={24} />

      <Container maxWidth='1160px'>
        <Row justifyContent={justifyPaginationEnd}>
          <Pagination />
        </Row>
      </Container>

      <Spacer.Horizontal size={24} />
    </>
  )
}