import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Format product price steps to shelf price list
 * @param {unknown} product - product data
 * @returns
 */
export function formatPriceList(product: unknown) {
  let priceList = []

  if (isValidArrayWithData(product?.['priceSteps'])) {
    priceList = product?.['priceSteps'].slice().sort((item: unknown, nextItem: unknown) => {
      return nextItem['minQuantity'] - item['minQuantity']
    })
  }

  return priceList
}
