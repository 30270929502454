import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { ConnectionCfHeaderMenu } from '../../../shared/utils/connectionCfHeader'
import { AlviButtons } from 'shared/helpers'
import { useConnectionCFHeaderImages } from 'shared/utils/connectionCFHeaderImages'
import { useConnectionCFMyAccount } from 'shared/utils/connectionCFMyAccount'
import { ContentfulResponse, IContentfulResponse, searchContentfulItemsBy } from '@smu-chile/pkg-unimarc-hooks'

interface IHandleClickDropDown {
  onClickGoToClubAlvi?: () => void
  onClickGoToBussinessPartner?: () => void
  onClickGoToHelpCenter?: () => void
  onClickGoToAskHere?: () => void
  onClickGoToLocationsAndSchedules?: () => void
  onClickGoToMyPersonalInformation?: () => void
  onClickGoToMyShopping?: () => void
}

export interface IVariableBreakdownCF {
  cfDataHeader?: object
  handleClicks?: IHandleClickDropDown
}

export const variableBreakdownCF = (userLevel, router, setIsVisibleMyProfile): IVariableBreakdownCF => {
  const { dataHeaderCF } = ConnectionCfHeaderMenu()

  const buttonMyShopping = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.sys?.id === '2dRNAFbX7paX5WKQuwrHrc'.toString()
  })
  const buttonSingOff = dataHeaderCF?.includes?.Entry?.find((data) => { return data?.fields?.label.toLowerCase().includes('cerrar sesión') })
  const buttonCoupon = dataHeaderCF?.includes?.Entry?.find((data) => { return data?.fields?.label.toLowerCase().includes('cupon') })
  const buttonPersonalInformation = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.fields?.label.toLowerCase().includes('datos personales') && data?.fields?.alviEnabledLevels?.includes(AlviButtons[userLevel])
  })
  const buttonBussinessPartner = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.fields?.label.toLowerCase().includes('comerciante') && data?.fields?.alviEnabledLevels?.includes(AlviButtons[userLevel])
  })
  const buttonHelpCenter = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.fields?.label.toLowerCase().includes('centro de ayuda') && data?.fields?.alviEnabledLevels?.includes(AlviButtons[userLevel])
  })
  const buttonAskHere = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.fields?.label.toLowerCase().includes('cotiza') && data?.fields?.alviEnabledLevels?.includes(AlviButtons[userLevel])
  })
  const buttonLocationsAndSchedules = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.fields?.label.toLowerCase().includes('locales y horarios') && data?.fields?.alviEnabledLevels?.includes(AlviButtons[userLevel])
  })
  const buttonClubAlvi = dataHeaderCF?.includes?.Entry?.find((data) => {
    return data?.fields?.label.toLowerCase().includes('club') && data?.fields?.alviEnabledLevels?.includes(AlviButtons[userLevel])
  })

  // Icons
  const iconMyShopping = buttonMyShopping?.fields?.iconText as keyof typeof Icons
  const iconSingOff = buttonSingOff?.fields?.iconText as keyof typeof Icons
  const iconCoupon = buttonCoupon?.fields?.iconText as keyof typeof Icons
  const iconPersonalInformation = buttonPersonalInformation?.fields?.iconText as keyof typeof Icons
  const iconBussinessPartner = buttonBussinessPartner?.fields?.iconText as keyof typeof Icons
  const iconHelpCenter = buttonHelpCenter?.fields?.iconText as keyof typeof Icons
  const iconAskHere = buttonAskHere?.fields?.iconText as keyof typeof Icons
  const iconLocationsAndSchedules = buttonLocationsAndSchedules?.fields?.iconText as keyof typeof Icons
  const iconClubAlvi = buttonClubAlvi?.fields?.iconText as keyof typeof Icons
  // Labels
  const labelMyShopping = buttonMyShopping?.fields?.label
  const labelSingOff = buttonSingOff?.fields?.label
  const labelCoupon = buttonCoupon?.fields?.label
  const labelPersonalInformation = buttonPersonalInformation?.fields?.buttonText
  const labelBussinessPartner = buttonBussinessPartner?.fields?.buttonText
  const labelHelpCenter = buttonHelpCenter?.fields?.buttonText
  const labelAskHere = buttonAskHere?.fields?.buttonText
  const labelLocationsAndSchedules = buttonLocationsAndSchedules?.fields?.buttonText
  const labelClubAlvi = buttonClubAlvi?.fields?.buttonText
  // Show button
  const showBtnMyShopping = buttonMyShopping?.fields?.showbuttom
  const showBtnSingOff = buttonSingOff?.fields?.showbuttom
  const showBtnCoupon = buttonCoupon?.fields?.showbuttom
  const showBtnPersonalInformation = buttonPersonalInformation?.fields?.showbuttom
  const showBtnBussinessPartner = buttonBussinessPartner?.fields?.showbuttom
  const showBtnHelpCenter = buttonHelpCenter?.fields?.showbuttom
  const showBtnAskHere = buttonAskHere?.fields?.showbuttom
  const showBtnLocationsAndSchedules = buttonLocationsAndSchedules?.fields?.showbuttom
  const showBtnClubAlvi = buttonClubAlvi?.fields?.showbuttom
  // Show label
  const showLabelMyShopping = buttonMyShopping?.fields?.showLabel
  const showLabelCoupon = buttonCoupon?.fields?.showLabel
  const showLabelSingOff = buttonSingOff?.fields?.showLabel
  const showLabelPersonalInformation = buttonPersonalInformation?.fields?.showLabel
  const showLabelBussinessPartner = buttonBussinessPartner?.fields?.showLabel
  const showLabelHelpCenter = buttonHelpCenter?.fields?.showLabel
  const showLabelAskHere = buttonAskHere?.fields?.showLabel
  const showLabelLocationsAndSchedules = buttonLocationsAndSchedules?.fields?.showLabel
  const showLabelClubAlvi = buttonClubAlvi?.fields?.showLabel
  // Text label
  const textLabelMyShopping = buttonMyShopping?.fields?.labelNewText
  const textLabelSingOff = buttonSingOff?.fields?.labelNewText
  const textLabelCoupon = buttonCoupon?.fields?.labelNewText
  const textLabelPersonalInformation = buttonPersonalInformation?.fields?.labelNewText
  const textLabelBussinessPartner = buttonBussinessPartner?.fields?.labelNewText
  const textLabelHelpCenter = buttonHelpCenter?.fields?.labelNewText
  const textLabelAskHere = buttonAskHere?.fields?.labelNewText
  const textLabelLocationsAndSchedules = buttonLocationsAndSchedules?.fields?.labelNewText
  const textLabelClubAlvi = buttonClubAlvi?.fields?.labelNewText
  // Color label
  const colorLabelMyShopping = buttonMyShopping?.fields?.labelColorText
  const colorLabelSingOff = buttonSingOff?.fields?.labelColorText
  const colorLabelCoupon = buttonCoupon?.fields?.labelColorText
  const colorLabelPersonalInformation = buttonPersonalInformation?.fields?.labelColorText
  const colorLabelBussinessPartner = buttonBussinessPartner?.fields?.labelColorText
  const colorLabelHelpCenter = buttonHelpCenter?.fields?.labelColorText
  const colorLabelAskHere = buttonAskHere?.fields?.labelColorText
  const colorLabelLocationsAndSchedules = buttonLocationsAndSchedules?.fields?.labelColorText
  const colorLabelClubAlvi = buttonClubAlvi?.fields?.labelColorText
  // BackgroundLabel
  const backgroundLabelMyShopping = buttonMyShopping?.fields?.labelColorBackground
  const backgroundLabelSingOff = buttonSingOff?.fields?.labelColorBackground
  const backgroundLabelCoupon = buttonCoupon?.fields?.labelColorBackground
  const backgroundLabelPersonalInformation = buttonPersonalInformation?.fields?.labelColorBackground
  const backgroundLabelBussinessPartner = buttonBussinessPartner?.fields?.labelColorBackground
  const backgroundLabelHelpCenter = buttonHelpCenter?.fields?.labelColorBackground
  const backgroundLabelAskHere = buttonAskHere?.fields?.labelColorBackground
  const backgroundLabelLocationsAndSchedules = buttonLocationsAndSchedules?.fields?.labelColorBackground
  const backgroundLabelClubAlvi = buttonClubAlvi?.fields?.labelColorBackground

  const cfDataHeader = {
    // background
    backgroundLabelCoupon,
    backgroundLabelMyShopping,
    backgroundLabelSingOff,
    backgroundLabelPersonalInformation,
    backgroundLabelBussinessPartner,
    backgroundLabelHelpCenter,
    backgroundLabelAskHere,
    backgroundLabelLocationsAndSchedules,
    backgroundLabelClubAlvi,
    // color
    colorLabelCoupon,
    colorLabelMyShopping,
    colorLabelSingOff,
    colorLabelPersonalInformation,
    colorLabelBussinessPartner,
    colorLabelHelpCenter,
    colorLabelAskHere,
    colorLabelLocationsAndSchedules,
    colorLabelClubAlvi,
    // icon
    iconCoupon,
    iconMyShopping,
    iconSingOff,
    iconPersonalInformation,
    iconBussinessPartner,
    iconHelpCenter,
    iconAskHere,
    iconLocationsAndSchedules,
    iconClubAlvi,
    // label
    labelCoupon,
    labelMyShopping,
    labelSingOff,
    labelPersonalInformation,
    labelBussinessPartner,
    labelHelpCenter,
    labelAskHere,
    labelLocationsAndSchedules,
    labelClubAlvi,
    // show btn
    showBtnCoupon,
    showBtnMyShopping,
    showBtnSingOff,
    showBtnPersonalInformation,
    showBtnBussinessPartner,
    showBtnHelpCenter,
    showBtnAskHere,
    showBtnLocationsAndSchedules,
    showBtnClubAlvi,
    // show label
    showLabelCoupon,
    showLabelMyShopping,
    showLabelSingOff,
    showLabelPersonalInformation,
    showLabelBussinessPartner,
    showLabelHelpCenter,
    showLabelAskHere,
    showLabelLocationsAndSchedules,
    showLabelClubAlvi,
    // text label
    textLabelCoupon,
    textLabelMyShopping,
    textLabelSingOff,
    textLabelPersonalInformation,
    textLabelBussinessPartner,
    textLabelHelpCenter,
    textLabelAskHere,
    textLabelLocationsAndSchedules,
    textLabelClubAlvi
  }

  const handleClick = (route) => {
    router.push(route)
    setIsVisibleMyProfile(false)
  }

  const handleClicks = {
    onClickGoToBussinessPartner: () => {
      handleClick(buttonBussinessPartner?.fields?.url)
    },
    onClickGoToHelpCenter: () => {
      handleClick(buttonHelpCenter?.fields?.url)
    },
    onClickGoToAskHere: () => {
      handleClick(buttonAskHere?.fields?.url)
    },
    onClickGoToLocationsAndSchedules: () => {
      handleClick(buttonLocationsAndSchedules?.fields?.url)
    },
    onClickGoToMyPersonalInformation: () => {
      handleClick(buttonPersonalInformation?.fields?.url)
    },
    onClickGoToMyShopping: () => {
      handleClick(buttonMyShopping?.fields?.url)
    },
    onClickGoToClubAlvi: () => {
      handleClick(buttonClubAlvi?.fields?.url)
    }
  }

  return {
    cfDataHeader,
    handleClicks
  }
}

export const useImagesDropDownMenu = (userLevel: string, router) => {
  const { dataHeaderImagesCF } = useConnectionCFHeaderImages()

  if (userLevel) {
    const imageEntry = dataHeaderImagesCF?.includes?.Entry.find((entry) => {
      return entry?.fields?.alviEnabledLevels.includes(AlviButtons[userLevel])
    })
    const imageAsset = dataHeaderImagesCF?.includes?.Asset.find((asset) => {
      return imageEntry?.fields?.image?.sys?.id === asset?.sys?.id
    })

    const imageDropDown = {
      imageUrl: `https:${imageAsset?.fields?.file?.url}`,
      onClick: () => {
        router.push(`${process.env.NEXT_PUBLIC_HOMEURL}/club-alvi`)
      }
    }

    return imageDropDown
  }

  return null
}

export const useContentfulMyAccount = () => {
  const { dataHeaderMyAccount: myAccountData } = useConnectionCFMyAccount()

  const response = new ContentfulResponse(myAccountData as IContentfulResponse)
  const populate = response.populateEntries().getResponse()

  const [myAccountFiltered] = populate?.items ? searchContentfulItemsBy(populate?.items, 'name', 'Menu Mi Cuenta Alvi') : [{}]

  const myAccountBlocks = () => {
    const hasBlock = !!myAccountFiltered?.fields?.infoBlocks
    const [blockForLevel] = hasBlock ? searchContentfulItemsBy(myAccountFiltered?.fields?.infoBlocks, 'blockId', 'AlviProfileLevelsHeader') : []
    const [blockForLevelActions] = hasBlock ? searchContentfulItemsBy(myAccountFiltered?.fields?.infoBlocks, 'blockId', 'headersButtonsAlviProfile') : []
    const [blockForLevelBenefits] = hasBlock ? searchContentfulItemsBy(myAccountFiltered?.fields?.infoBlocks, 'blockId', 'benefitsByLevels') : []

    return {
      blocks: myAccountFiltered?.fields?.components,
      blocksForLevel: blockForLevel?.fields?.items,
      blocksForLevelBenefits: blockForLevelBenefits?.fields?.items,
      blocksForLevelActions: blockForLevelActions?.fields?.items
    }
  }

  return { data: myAccountBlocks() }
}