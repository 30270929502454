import {
  Backbone,
  Column,
  Horizontal,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen, SmallScreen } from '@smu-chile/pkg-unimarc-components/helpers'

export const BackboneTitle = () => {
  return (
    <>
      <Horizontal size={32} />
      <BigScreen>
        <>
          <Row justifyContent='between' maxWidth='1136px'>
            <Column>
              <Row maxWidth='220px'>
                <Backbone borderRadius='8px' height={30} />
              </Row>
              <Horizontal size={12} />
              <Row maxWidth='200px'>
                <Backbone borderRadius='8px' height={25} />
              </Row>
            </Column>
            <Column alignItems='end'>
              <Row maxWidth='100px'>
                <Backbone borderRadius='8px' height={25} />
              </Row>
            </Column>
          </Row>
        </>
      </BigScreen>

      <SmallScreen>
        <>
          <Row justifyContent='between' maxWidth='92%'>
            <Column>
              <Row maxWidth='150px'>
                <Backbone borderRadius='8px' height={22} />
              </Row>
              <Horizontal size={8} />
              <Row maxWidth='140px'>
                <Backbone borderRadius='8px' height={18} />
              </Row>
            </Column>
            <Column alignItems='end'>
              <Row maxWidth='80px'>
                <Backbone borderRadius='8px' height={22} />
              </Row>
            </Column>
          </Row>
        </>
      </SmallScreen>
      <Horizontal size={24} />
    </>
  )
}