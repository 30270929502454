interface PaginationCalProps {
    page:number;
    limit: number;
}

export const paginationCal = ({page,limit}:PaginationCalProps) =>{
  let offset = 0
  let end = limit -1
  if(page !== 1 && typeof page === 'number'){
    offset = Math.floor(((page - 1) * (limit )) )
    end = Math.floor((page * limit) - 1)
  }
  
  return {
    offset,
    end
  }
}