import { useState } from 'react'
import {
  useEvents,
  useMobile,
  useProductsMyUsual,
  useProductsByIdentifier,
  useUpdateFromShelfAlvi,
  useUser
} from '@smu-chile/pkg-unimarc-hooks'

import { useProductsCarousel, useShelvesData } from '../helpers/useProductsCarousel'
import { ContentCarousel } from '../ContentCarousel'
import { ProductCarouselProps } from '../Collection'

export const CarouselMyUsual = ({ clusterData, titleSizes }: ProductCarouselProps) => {
  // use
  const { isMobile } = useMobile()
  const user = useUser()
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const dataMyUsual = useProductsMyUsual({})

  const values = dataMyUsual?.data?.data?.availableProducts ? dataMyUsual?.data?.data?.availableProducts.map(product => {
    return product?.item.ean
  }) : []

  const productsProps = {
    field: 'ean',
    values
  }
  const productsCluster = useProductsByIdentifier({
    ...productsProps,
    reactQuery: { enabled: values !== undefined }
  })
  const { availableProducts } = productsCluster?.data?.data || {}

  const newListProducts = availableProducts?.map(product => {
    const detailUrl = product?.detailUrl?.split('/')?.[3]
    return {
      ...product,
      detailUrl
    }
  })
  const {
    linkFontSize,
    dataPromotions,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    carouselHeaderPadding,
    spacerBelow,
    resultPromotions
  } = useProductsCarousel({ isMobile })
  const productLimit = newListProducts?.slice(0, 11)
  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelfAlvi({ isOrderFormLoading, products: productLimit, userId: user?.userId })
  const productsMemo = useShelvesData(shelvesData, dataPromotions, isMobile)
  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm', callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  if (!dataMyUsual?.data?.data?.[0] || dataMyUsual?.data?.error) return null

  const carouselShelData = {
    positionContainer,
    handleOnClick,
    productsMemo,
    itemsToShow,
    handleAdd,
    handleRemove,
    handleChange,
    itemsToSlide,
    spacerBelow
  }
  const isLoading: boolean = productsCluster?.isLoading ||
    resultPromotions?.isLoading || dataPromotions?.isLoading

  if (!dataMyUsual?.data?.data[0] || dataMyUsual?.data?.error) return null

  return (
    <ContentCarousel
      carouselHeaderPadding={carouselHeaderPadding}
      carouselShelData={carouselShelData}
      clusterData={clusterData}
      isLoading={isLoading}
      isMobile={isMobile}
      linkFontSize={linkFontSize}
      titleSizes={titleSizes}
    />
  )
}