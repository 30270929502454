import { useMemo } from 'react'
import { formatProductUrl, useContentful } from '@smu-chile/pkg-unimarc-hooks'
import {
  getDataPromotion,
  promotionsOfShelves,
  statusPromotions
} from 'shared/helpers'
import { FontSizeLink } from '@smu-chile/pkg-unimarc-components/stories/atoms/Links/Link'
import { Sizes } from '@smu-chile/pkg-unimarc-components/stories/layout/Spacer/Horizontal'
import { formatPriceList } from 'components/Shelves/helpers/formatPriceList'
import { formatPromotion } from 'components/Shelves/helpers/formatPromotion'
import { formatQuantityDisplay } from 'components/Shelves/helpers/formatQuantityDisplay'

interface PositionProp {
  maxWidth: string
  maxHeight: string
  gap?: number
  position: 'relative' | 'initial'
}

export const useProductsCarousel = ({ isMobile }: { isMobile: boolean }) => {
  const resultPromotions = useContentful({
    options: { content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE }
  })
  const linkFontSize: FontSizeLink = isMobile ? 'sm' : 'lg'
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })
  const positionContainer: PositionProp = {
    position: !isMobile ? 'relative' : 'initial',
    maxWidth: '1120px',
    gap: isMobile ? 0 : 30,
    maxHeight: isMobile ? '500px' : 'initial'
  }
  const itemsToShow = isMobile ? 2 : 5
  const itemsToSlide = isMobile ? 2 : 5
  const carouselHeaderPadding = isMobile ? '15px 20px' : '15px 0'
  const spacerBelow: Sizes = isMobile ? 12 : 32

  return {
    linkFontSize,
    dataPromotions,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    carouselHeaderPadding,
    spacerBelow,
    resultPromotions
  }
}

export const useShelvesData = (shelvesData, dataPromotions, isMobile) => {
  return useMemo(() => {
    return (
      shelvesData?.map((item) => {
        const { isPromotion, price, ppum, offer } = statusPromotions(item)
        const promotion = getDataPromotion(
          dataPromotions,
          item?.promotion?.['name']
        )
        const url = formatProductUrl(item['detailUrl'])
        const maxQuantity =
          item?.cartLimit > 0
            ? item?.cartLimit
            : item?.sellers[0]?.availableQuantity

        const productPriceList = formatPriceList(item)
        const productPromotion = formatPromotion(item)
        const productQuantityDisplay = formatQuantityDisplay(item)

        return {
          ...item,
          brand: item?.brand,
          format: item?.format,
          img: item?.images[0],
          inOffer: item?.sellers[0]?.inOffer,
          isMobile: isMobile,
          isPromotion: isPromotion,
          key: item?.itemId,
          likeListButtons: false,
          listPrice: item?.sellers[0]?.listPrice,
          offer: offer,
          ppum: `(${ppum})`,
          ppumListPrice: `(${item?.sellers[0]?.ppumListPrice})`,
          price: price,
          priceSteps: productPriceList,
          promotion: promotion,
          promotionalTag: productPromotion,
          quantity: item?.quantityButtonProps?.quantity,
          quantityButtonProps: {
            ...item.quantityButtonProps,
            maxQuantity
          },
          quantityDisplay: productQuantityDisplay,
          title: item?.name,
          typePromotion: item?.promotion?.['descriptionCode'],
          url,
          version: 2,
          width: isMobile ? '166px' : '200px'
        }
      }) ?? []
    )
  }, [shelvesData])
}
