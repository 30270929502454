export interface UseAccountTooltipProps {
  type: string;
}

export const useAccountTooltip = ({ type }: UseAccountTooltipProps) => {
  const tooltipMessages = {
    loginDocument: {
      message: 'Te informaremos cuando tu usuario esté validado',
      title: '¡Documento subido exitosamente!'
    },
    register: {
      message: 'Conoce aquí todo sobre tu cuenta y beneficios del ahorro.',
      title: '¡Ya eres parte de Club Alvi.cl!'
    }
  }

  return tooltipMessages[type] ?? {}
}
