import {
  Container,
  Spacer,
  ErrorView,
  ErrorViewProps,
  Text,
  Column
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useState } from 'react'
import styles from './Error.module.css'
import { useRouter } from 'next/router'

interface ErrorViewFeProps extends Omit<ErrorViewProps, 'onClick'> {
  route?: string;
  site?: 'alvi';
}

export const ErrorPage = ({
  body1 = 'Estamos limpiado nuestros pasillos',
  body2 = 'No encontramos lo que estás buscando.',
  buttonLabel = 'Ir al home',
  errorImage,
  errorType,
  redirect = true,
  route = process.env.NEXT_PUBLIC_HOMEURL,
  site = 'alvi',
  title = 'Ooops..'
}: ErrorViewFeProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const router = useRouter()
  const typeError = errorType === '500'
  const errorViewProps = {
    body1: typeError ? 'Tuvimos un problema ' : body1,
    body2: typeError ? 'Pronto lo solucionaremos. Intenta recargando la página ' : body2,
    buttonLabel: typeError ? 'Recargar' : buttonLabel,
    errorImage,
    errorType,
    title: typeError ? 'Lo sentimos' : title,
    backgroundButton: getGlobalStyle('--color-alvi-primary-blue-gradient'),
    redirect
  }

  const onClickError = () => {
    return router.push(route)
  }
  const [year, setYear] = useState(0)
  useEffect(() => {
    const currentYear = new Date().getFullYear()
    setYear(currentYear)
  }, [])

  return (
    <>
      <Column
        alignItems='center'
        backgroundColor={getGlobalStyle('--color-background-white')}
        justifyContent='center'
        minHeight={!isMobile && '543px'}
        tagName='section'
      >
        <Container
          customWidth='90'
          height={'441px'}
          isWrap
          justifyContent='center'
          margin='145px 0'
          maxWidth='1160px'
        >
          <Spacer.Horizontal size={48} />
          <ErrorView
            {...errorViewProps}
            colorErrorType={'gray'}
            onClick={onClickError}
            site={site}
          />
          <Spacer.Horizontal size={48} />
        </Container>
      </Column>
      {typeError && <Column
        alignItems='center'
        background={getGlobalStyle('--color-background-white')}
        className={`${styles.footerError}`}
        customHeight={'47px'}
        justifyContent='center'
        margin='20px 0'
        position='fixed'
      >
        <Spacer.Horizontal size={16} />
        <Text
          color={'neutral-black'}
          fontSize='md'
          fontWeight='semibold'
          lineHeight={getGlobalStyle('--line-height-md')}
        >
          {`© ${year} SMU | Todos los derechos reservados`}
        </Text>
        <Spacer.Horizontal size={16} />
      </Column>}
    </>
  )
}