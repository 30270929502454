export const dataForBreadcrumbs = ({
  router,
  search,
  brand = false
}) => {
  const handleOnClickBreadCrumbs = (url: string) => {
    if (url) return router.push(url)
    return null
  }
  const labelTitle = brand ? 'Marca' : 'Búsqueda'
  const breadcrumbData = [
    { label: 'Inicio', url: '/', oneClick: handleOnClickBreadCrumbs },
    { label: labelTitle },
    { label: `“${search}”` }
  ]
  const title = search

  return {
    title,
    breadcrumbData
  }
}