import { ButtonStatus, TitleProps } from '@smu-chile/pkg-unimarc-components'

type Action =
  {
    type: 'OPEN_ERROR';
    payload: { error: boolean };
  }
  |
  {
    type: 'SEND_MESSAGE_MODAL';
    payload: {
      alignTitle?: TitleProps['textAlign'];
      buttonText?: string;
      colorTitle?: string;
      description?: string;
      position?: TitleProps['textAlign'];
      title?: string;
    };
  }
  |
  {
    type: 'SAVE_LOCATION_FORMAT';
    payload: {
      commune?: string;
      country?: string;
      lat?: number;
      lng?: number;
      municipality?: string;
    };
  }
  |
  {
    type: 'OPEN_MODAL';
    payload: { modal?: boolean }
  }
  |
  {
    type: 'ADDRESS_NUMBER_STREET';
    payload: { addressStreetNumber?: boolean }
  }
  |
  {
    type: 'SET_STATUS_BUTTON';
    payload: { buttonStatus?: ButtonStatus }
  }
  |
  {
    type: 'SET_MODAL_EVENT';
    payload: { modalEvent?: string }
  }
  |
  {
    type: 'SET_ERROR_MAP_MESSAGE';
    payload: { errorMapMessage?: string; typeError?: string }
  }
  |
  {
    type: 'ARROW_UP';
    payload: { data }
  }
  |
  {
    type: 'ARROW_DOWN';
    payload: { data }
  }
  
export const handleReducer = (status: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'OPEN_ERROR': {
      const error = action.payload.error === status.error ? false : action.payload
      return {
        ...status,
        error: error
      }
    }
    case 'SEND_MESSAGE_MODAL': {
      const {
        alignTitle,
        buttonText,
        colorTitle,
        description,
        position,
        title
      } = action.payload
      return {
        ...status,
        message: {
          alignTitle: alignTitle,
          buttonText: buttonText,
          colorTitle: colorTitle,
          description: description,
          position: position,
          title: title
        }
      }
    }
    case 'SAVE_LOCATION_FORMAT': {
      const {
        commune,
        country,
        lat,
        lng,
        municipality
      } = action.payload
      return {
        ...status,
        geolocation: {
          commune: commune,
          country: country,
          lat: lat,
          lng: lng,
          municipality: municipality
        }
      }
    }
    case 'OPEN_MODAL': {
      const modal = action.payload.modal === status.modal ? false : action.payload.modal
      return {
        ...status,
        modal: modal
      }
    }
    case 'ADDRESS_NUMBER_STREET': {
      const addressStreetNumber = action.payload.addressStreetNumber
      return {
        ...status,
        addressStreetNumber: addressStreetNumber
      }
    }
    case 'SET_STATUS_BUTTON': {
      return {
        ...status,
        buttonStatus: action.payload.buttonStatus
      }
    }
    case 'SET_MODAL_EVENT': {
      return {
        ...status,
        modalEvent: action.payload.modalEvent
      }
    }
    case 'ARROW_UP': {
      const data = action.payload.data
      return {
        ...status,
        selectedIndex: status.selectedIndex !== 0 ? status.selectedIndex - 1 : data.length - 1
      }
    }
    case 'ARROW_DOWN': {
      const data = action.payload.data
      return {
        ...status,
        selectedIndex: status.selectedIndex !== data.length - 1 ? status.selectedIndex + 1 : 0
      }
    }
    default: return status
  }
}

export const initialState = {
  addressStreetNumber: false,
  buttonStatus: 'initial',
  selectedIndex: -1,
  error: false,
  errorAddressMessage: '',
  message: {
    alignTitle: 'center',
    buttonText: '',
    colorTitle: '',
    description: '',
    position: 'center',
    title: ''
  },
  modal: false,
  modalEvent: '',
  geolocation: {
    commune: '',
    country: '',
    lat: 0,
    lng: 0,
    municipality: ''
  }
}
