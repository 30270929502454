import { Column, ConfirmModal, ConfirmModalProps } from '@smu-chile/pkg-unimarc-components'
import { BigScreen, SmallScreen, getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export type ConfirmWrapModalProps = ConfirmModalProps

export const ConfirmWrapModal = ({ children, ...props }: ConfirmWrapModalProps) => {
  return (
    <>
      <BigScreen>
        <ConfirmModal
          buttonBorderRadius={getGlobalStyle('--border-radius-lg')}
          buttonLabel='Ir a comprar'
          buttonLabelWeight={getGlobalStyle('--font-weight-medium')}
          headerDividerColor={getGlobalStyle('--color-neutral-gray-divider')}
          headerDividerSize='3xs'
          headerProps={{
            appendIconSize: 20,
            appendIconContainerSize: '36px'
          }}
          site='alvi'
          titleWeight='semibold'
          width='375px'
          {...props}
        >
          <Column
            alignItems='center'
            margin='16px 0'
          >
            <Column
              alignItems='center'
              gap={16}
              maxWidth='343px'
            >
              {children}
            </Column>
          </Column>
        </ConfirmModal>
      </BigScreen>

      <SmallScreen>
        <ConfirmModal
          buttonBorderRadius={getGlobalStyle('--border-radius-lg')}
          buttonLabel='Ir a comprar'
          buttonLabelWeight={getGlobalStyle('--font-weight-medium')}
          footerContainerProps={{
            padding: '16px 16px 36px'
          }}
          fullScreen
          headerDividerColor={getGlobalStyle('--color-neutral-gray-divider')}
          headerDividerSize='3xs'
          site='alvi'
          titleWeight='semibold'
          {...props}
        >
          <Column
            alignItems='center'
            margin='16px 0'
          >
            <Column
              alignItems='center'
              gap={16}
              padding='0 16px'
            >
              {children}
            </Column>
          </Column>
        </ConfirmModal>
      </SmallScreen>
    </>
  )
}
