import { cleanRut } from '@smu-chile/pkg-unimarc-components/utils'
import {
  sleep,
  trigger,
  getGuestUserLevelsAlvi
} from '@smu-chile/pkg-unimarc-hooks'
import { SetStateAction, useCallback, useRef, useState } from 'react'

interface UseQueryMembershipLevel {
  showModalMembershipLevelQuery: boolean
  blockedBySecurity: boolean
  showModalMembershipType: boolean
  membershipType: string
  showModalRegisterClub: boolean
  isLoading: boolean
  setShowModalMembershipLevelQuery: React.Dispatch<SetStateAction<boolean>>
  handleToggleMembershipLevelQuery: () => void
  handleMembershipLevelQueryContinue: (rut: string, email?: string) => void
  handleToggleMembershipType: () => void
  handleToggleRegisterClub: () => void
}

/**
 * Custom hook to manage the query of membership levels.
 *
 * @returns {UseQueryMembershipLevel} - An object containing states and handlers for membership level queries.
 *
 * @example
 * ```typescript
 * const {
 *   showModalMembershipLevelQuery,
 *   blockedBySecurity,
 *   showModalMembershipType,
 *   membershipType,
 *   showModalRegisterClub,
 *   setShowModalMembershipLevelQuery,
 *   handleToggleMembershipLevelQuery,
 *   handleMembershipLevelQueryContinue,
 *   handleToggleMembershipType,
 *   handleToggleRegisterClub
 * } = useQueryMembershipLevel();
 * ```
 */
export const useQueryMembershipLevel = (): UseQueryMembershipLevel => {
  // membership level query
  const [showModalMembershipLevelQuery, setShowModalMembershipLevelQuery] =
    useState(false)
  // security block query level button
  const [blockedBySecurity, setBlockedBySecurity] = useState(false)
  const blockBySecurityCountRef = useRef(0)
  // membership type
  const [showModalMembershipType, setShowModalMembershipType] = useState(false)
  const [membershipType, setMembershipType] = useState('')
  // register club
  const [showModalRegisterClub, setShowModalRegisterClub] = useState(false)
  // is loading
  const [isLoading, setIsLoading] = useState(false)

  const handleToggleMembershipLevelQuery = () => {
    setShowModalMembershipLevelQuery(!showModalMembershipLevelQuery)
  }

  const handleMembershipLevelQueryContinue = useCallback(
    async (rut: string, email?: string) => {
      // execute recaptcha
      // start loading scenario
      setIsLoading(true)
      // prepare to block button by security
      if (blockedBySecurity) {
        setIsLoading(false)
        trigger({
          eventType: 'membershipToast',
          data: {
            show: true,
            message:
              'Has alcanzado el límite de consultas con tu Rut. Intenta más tarde.',
            errorCode: 'retries'
          }
        })
        setBlockedBySecurity(true)
        await sleep(10000)
        setBlockedBySecurity(false)
        blockBySecurityCountRef.current = 0
        return
      }
      blockBySecurityCountRef.current = blockBySecurityCountRef.current + 1

      if (blockBySecurityCountRef.current == 3) {
        setIsLoading(false)
        trigger({
          eventType: 'membershipToast',
          data: {
            show: true,
            message:
              'Has alcanzado el límite de consultas con tu Rut. Intenta más tarde.',
            errorCode: 'retries'
          }
        })
        setBlockedBySecurity(true)
        await sleep(10000)
        setBlockedBySecurity(false)
        blockBySecurityCountRef.current = 0
        return
      }
      // query the user level
      const cleanedDocument = cleanRut(rut)
      const queryMembershipUser = await getGuestUserLevelsAlvi({
        document: cleanedDocument,
        email
      })
      setIsLoading(false)
      const suscriptionType = queryMembershipUser?.userInfo?.level
      // if error status 429 too many requests show toast
      if (queryMembershipUser?.status === 429) {
        trigger({
          eventType: 'membershipToast',
          data: {
            show: true,
            message:
              'Has alcanzado el límite de consultas con tu Rut. Intenta más tarde.',
            errorCode: queryMembershipUser?.code || 'unknown'
          }
        })
        return
      }
      // if error status 400 or 500 show toast
      if (
        queryMembershipUser?.status === 400 ||
        queryMembershipUser?.status >= 500
      ) {
        trigger({
          eventType: 'membershipToast',
          data: {
            show: true,
            message:
              'Lo sentimos, no podemos mostrar la información. Intenta de nuevo.'
          }
        })
        return
      }
      if (queryMembershipUser?.error) {
        setShowModalMembershipLevelQuery(false)
        setShowModalRegisterClub(true)
      }
      if (suscriptionType) {
        setMembershipType(suscriptionType)
        setShowModalMembershipLevelQuery(false)
        setShowModalMembershipType(true)
      }
    },
    [blockedBySecurity]
  )

  const handleToggleMembershipType = () => {
    setShowModalMembershipType(!showModalMembershipType)
  }

  const handleToggleRegisterClub = () => {
    setShowModalRegisterClub(!showModalRegisterClub)
  }

  return {
    showModalMembershipLevelQuery,
    blockedBySecurity,
    showModalMembershipType,
    membershipType,
    showModalRegisterClub,
    isLoading,
    setShowModalMembershipLevelQuery,
    handleToggleMembershipLevelQuery,
    handleMembershipLevelQueryContinue,
    handleToggleMembershipType,
    handleToggleRegisterClub
  }
}
