import {
  Button,
  Column,
  Container,
  Icon,
  Modal,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle, SmallScreen } from '@smu-chile/pkg-unimarc-components/helpers'

export interface UpdateDocumentModalProps {
  isOpen?: boolean;
  onButtonClick?: () => void;
  onClose?: () => void;
}

export const FailedLoginModal = ({
  isOpen,
  onButtonClick,
  onClose
}: UpdateDocumentModalProps) => {
  const handleButtonClick = () => {
    if (onButtonClick) onButtonClick()
  }

  return (
    <>
      <SmallScreen>
        <Modal
          fullScreen
          iconPadding='1.5em'
          isOpen={isOpen}
          level='3'
          padding='0'
          toggle={onClose}
        >
          <Column customHeight='calc(100vh - 6rem)' justifyContent='between'>
            <Column overflow='auto'>
              <Column gap={24} padding='4rem 2rem'>
                <Column alignItems='center' gap={20}>
                  <Text fontSize='2xl' fontWeight='semibold'>
                    Tu cuenta está bloqueada
                  </Text>

                  <Icon customSize={80} name='InfoThin' />

                  <Text
                    fontSize='lg'
                    isFlex={false}
                    textAlign='center'
                  >
                    ¡Lo sentimos! <Text isFlex={false} type='b'>No pudimos validar</Text> que eres
                    comerciante. Por favor acércate a una de <Text isFlex={false} type='b'>nuestras sucursales </Text>
                    para  que nuestras <Text isFlex={false} type='b'>anfitrionas</Text> te ayuden a <Text isFlex={false} type='b'>activar tu cuenta</Text>.
                  </Text>
                </Column>
              </Column>
            </Column>

            <Container
              boxShadow={getGlobalStyle('--box-shadow-xs')}
              justifyContent='center'
              padding='.75rem 2.5rem 2.5rem'
            >
              <Button
                borderRadius='24px'
                fontSize='14px'
                fontWeight='500'
                fullWidth
                label='Aceptar'
                maxWidth='300px'
                onClick={handleButtonClick}
                site='alvi'
                size='large'
              />
            </Container>
          </Column>
        </Modal>
      </SmallScreen>
    </>
  )
}
